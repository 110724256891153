import axios from "axios";
import Vue from "vue";

const state = {
  contentcategoriesFetched: false,
  newContentcategory: {
    id: 0,
    tag: "",
    name: "",
    description: "",
  },
  contentcategories: [
    // {
    //   id: 1,
    //   tag: "COBI.wms",
    //   name: "COBI.wms",
    //   description: "Content related to COBI.wms",
    // },
    // {
    //   id: 2,
    //   tag: "COBI.time",
    //   name: "COBI.time",
    //   description: "Content related to COBI.time",
    // },
    // {
    //   id: 3,
    //   tag: "COBI.ppc",
    //   name: "COBI.ppc",
    //   description: "Content related to COBI.ppc",
    // },
  ],
};

const getters = {
  allContentcategories: (state) => state.contentcategories,
  contentcategoryById: (state) => (id) => {
    return state.contentcategories.find((x) => x.id == id);
  },
  newContentcategory: (state) =>
    JSON.parse(JSON.stringify(state.newContentcategory)),
};

const actions = {
  fetchContentcategories({ commit }) {
    Vue.prototype.$axios.get("/contentcategories").then((response) => {
      commit("SET_CONTENTCATEGORIES", response.data);
    });
  },
  fetchContentcategoriesAsync({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/contentcategories")
        .then((response) => {
          commit("SET_CONTENTCATEGORIES", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async postContentcategory({ commit }, contentcategory) {
    try {
      var response = await Vue.prototype.$axios.post(
        "/contentcategories",
        contentcategory
      );
      commit("ADD_CONTENTCATEGORY", response.data);
    } catch (exception) {
      var ret = exception.response.data[0].message;
      return ret;
    }
  },
  async patchContentcategory({ commit }, contentcategory) {
    try {
      var response = await Vue.prototype.$axios.patch(
        "/contentcategories",
        contentcategory
      );
      commit("UPDATE_CONTENTCATEGORY", contentcategory);
    } catch (exception) {
      var ret = exception.response.data[0].message;
      return ret;
    }
  },
  async deleteContentcategory({ commit }, contentcategory) {
    try {
      var response = await Vue.prototype.$axios.delete("/contentcategories", {
        data: contentcategory,
      });
      commit("DELETE_CONTENTCATEGORY", contentcategory);
    } catch (exception) {
      var ret = exception.response.data[0].message;
      return ret;
    }
  },
};

const mutations = {
  SET_CONTENTCATEGORIES(state, contentcategories) {
    state.contentcategories = contentcategories;
    state.contentcategoriesFetched = true;
  },
  ADD_CONTENTCATEGORY(state, contentcategory) {
    state.contentcategories.push(contentcategory);
  },
  UPDATE_CONTENTCATEGORY(state, contentcategory) {
    var idx = state.contentcategories.findIndex(
      (x) => x.id == contentcategory.id
    );
    Vue.set(state.contentcategories, idx, contentcategory);
  },
  DELETE_CONTENTCATEGORY(state, contentcategory) {
    var idx = state.contentcategories.findIndex(
      (x) => x.id == contentcategory.id
    );
    state.contentcategories.splice(idx, 1);
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
