<template>

  <v-btn
    v-if="item.icon && !item.children"
    icon
    :to="item.route"
  >
    <v-icon>{{ item.icon }}</v-icon>
  </v-btn>

  <v-menu 
    v-else-if="item.icon && item.children"
    offset-y
    open-on-hover
    transition="slide-y-transition"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        icon
        v-on="on"
        v-bind="attrs"
      >
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(child, index) in item.children"
        :key="index"
        link
        :to="child.route ? child.route : null"
        @click="child.execute ? child.execute() : null;"
      >
        <v-list-item-title
          align="left"
        >
          <span>{{ child.title }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

  <v-btn 
    v-else-if="!item.icon && !item.children"
    :to="item.route"
  >
    <span>{{ item.title }}</span>
  </v-btn>

  <v-menu 
    v-else
    offset-y
    open-on-hover
    transition="slide-y-transition"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn 
        v-on="on"
        v-bind="attrs"
      >
        <span>{{ item.title }}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(child, index) in item.children"
        :key="index"
        link
        :to="child.route ? child.route : null"
        @click="child.execute ? child.execute() : null;"
      >
        <v-list-item-title
          align="left"
        >
          <span>{{ child.title }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: 'ToolbarItem',
  props: {
    item: Object
  },
  data: () => ({

  }),
  methods: {

  }
}
</script>