import axios from "axios";
import Vue from "vue";

const state = {
  usersFetched: false,
  lastFetch: 0,
  usersOfGroups: [
    // name: "",
    // email: "",
  ],
  newUser: {
    id: null,
    name: "",
    email: "",
    customerid: null,
    contactid: null,
    is_admin: false,
    is_manager: false,
    is_customer: false,
    is_partner: false,
  },
  users: [
    // {
    //   id: 1,
    //   name: "admin",
    //   email: "admin",
    //   customerid: null,
    //   contactid: null,
    //   is_admin: true,
    //   is_manager: false,
    //   is_customer: false,
    //   is_partner: false,
    // },
    // {
    //   id: 2,
    //   name: "Manager User",
    //   email: "manager",
    //   customerid: null,
    //   contactid: null,
    //   is_admin: false,
    //   is_manager: true,
    //   is_customer: false,
    //   is_partner: false,
    // },
    // {
    //   id: 3,
    //   name: "Sample Customer 1 User",
    //   email: "customer1",
    //   customerid: 1,
    //   contactid: null,
    //   is_admin: false,
    //   is_manager: false,
    //   is_customer: false,
    //   is_partner: true,
    // },
    // {
    //   id: 4,
    //   name: "Sample Customer 2 User",
    //   email: "customer2",
    //   customerid: 2,
    //   contactid: null,
    //   is_admin: false,
    //   is_manager: false,
    //   is_customer: false,
    //   is_partner: true,
    // },
  ],
};

const getters = {
  allUsers: (state) => state.users,
  privilegedUsers: (state) =>
    state.users.filter((x) => x.is_admin || x.is_manager),
  regularUsers: (state) =>
    state.users.filter((x) => !(x.is_admin || x.is_manager)),
  userByContactId: (state) => (contactid) => {
    return state.users.find((x) => x.contactid == contactid);
  },
  userByUserId: (state) => (userid) => {
    return state.users.find((x) => x.id == userid);
  },
  userInGroup: (state) => (userid) => state.usersOfGroups.find(x => x.id == userid),
  adminUsers: (state) => state.users.filter((x) => x.is_admin),
  managerUsers: (state) => state.users.filter((x) => x.is_manager),
  partnerUsers: (state) => state.users.filter((x) => x.is_partner),
  customerUsers: (state) => state.users.filter((x) => x.is_customer),
};

const actions = {
  fetchAllGroupsOfUser({ commit }, userid) {
    return Vue.prototype.$axios.get("/users", { params: { "userid": userid } })
      .then((response) => {
        commit("SET_USERS_GROUPS", response.data);
      })
      .catch(e => e.response.data[0].message);
  },
  fetchUsers({ commit }) {
    return Vue.prototype.$axios.get("/users").then((response) => {
      commit("SET_USERS", response.data);
    });
  },
  fetchUserEmailById({ commit }) {
    return Vue.prototype.$axios.get("/users", { params: { "userid": userid } }).then((response) => response.data);
  },
  fetchUsersAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return;
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/users")
        .then((response) => {
          commit("SET_USERS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async postUser({ commit }, user) {
    try {
      // create new project -> use project data in user
      //var projectRes = await Vue.prototype.$axios.post("/project", { "name": user.name });
      var response = await Vue.prototype.$axios.post("/users", user
      /* {
        "projectString": projectRes.project.identifier,
        "project_id": projectRes.project.id,
        ...user,
      } */);
      commit("ADD_USER", response.data);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
  async patchUser({ commit }, user) {
    try {
      var response = await Vue.prototype.$axios.patch("/users", user);
      commit("UPDATE_USER", user);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
  async activateAccess({ commit }, object) {
    try {
      var response = await Vue.prototype.$axios.post("/activateAccess", object);
      commit("ADD_USER", response.data);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
  deleteUser({ commit }, userId) {
    Vue.prototype.$axios.delete("/users", { data: { "id": userId } })
      .then(res => {
        commit("DELETE_USER", userId)
      })
      .catch(exception => exception.response.data[0].message)
  }
};

const mutations = {
  SET_USERS(state, users) {
    state.users = users;
    state.usersFetched = true;
  },
  SET_USERS_GROUPS(state, usersOfGroups) {
    state.usersOfGroups = usersOfGroups;
  },
  ADD_USER(state, user) {
    state.users.push(user);
  },
  UPDATE_USER(state, user) {
    var idx = state.users.findIndex((x) => x.id == user.id);
    Vue.set(state.users, idx, user);
  },
  DELETE_USER(state, userId) {
    const index = state.users.findIndex(x => x.id == userId);
    state.users.splice(index, 1)
  }
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
