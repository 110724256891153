import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#b31616',
/*         secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3, */
      },
    },
  },
});
