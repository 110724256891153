import axios from "axios";
import Vue from "vue";

const state = {
  userloginsFetched: false,
  lastFetch: 0,
  userlogins: [],
  newUserlogin: {
    userid: null,
    timestamp: "",
  },
};

const getters = {
  allUserlogins: (state) => state.userlogins,
  userloginsByUserid: (state) => (userid) => {
    return state.userlogins
      .filter((x) => x.userid == userid)
      .map((x) => {
        return {
          userid: x.userid,
          timestamp: Date.parse(x.timestamp),
          timestampstring: x.timestamp,
        };
      });
  },
  lastUserloginByUserid: (state) => (userid) => {
    let last = state.userlogins.find((x) => x.userid == userid);
    if (!last)
      return {
        userid: null,
        timestamp: 0,
        timestampstring: "No known logins so far",
      };
    return {
      userid: last.userid,
      timestamp: Date.parse(last.timestamp),
      timestampstring: new Date(Date.parse(last.timestamp)).toLocaleString(
        "en-GB"
      ),
    };
  },
};

const actions = {
  fetchUserloginsAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return;
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/userlogins")
        .then((response) => {
          commit("SET_USERLOGINS", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_USERLOGINS(state, userlogins) {
    state.userlogins = userlogins;
    state.userloginsFetched = true;
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
