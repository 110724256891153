import Vue from "vue";
import Vuex from "vuex";
import applications from "./modules/applications";
import authentication from "./modules/authentication";
import contacts from "./modules/contacts";
import customerlicenses from "./modules/customerlicenses";
import customers from "./modules/customers";
import durationdiscounts from "./modules/durationdiscounts";
import globalsnackbar from "./modules/globalsnackbar";
import licensehasmodules from "./modules/licensehasmodules";
import modules from "./modules/modules";
import quantitydiscounts from "./modules/quantitydiscounts";
import subcustomers from "./modules/subcustomers";
import templatemodules from "./modules/templatemodules";
import templates from "./modules/templates";
import users from "./modules/users";
import contents from "./modules/contents";
import contentcategories from "./modules/contentcategories";
import contenthascategories from "./modules/contenthascategories";
import profile from "./modules/profile";
import announcement from "./modules/announcements";
import termsofservices from "./modules/termsofservices";
import termsofserviceagreements from "./modules/termsofserviceagreements";
import password from "./modules/password";
import messages from "./modules/messages";
import messaginglists from "./modules/messaginglists";
import messaginglistmembers from "./modules/messaginglistmembers";
import userlogins from "./modules/userlogins";
import topics from "./modules/topics";
import messagereads from "./modules/messagereads";
import issues from "./modules/redmine/issues";
import project from "./modules/redmine/project";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    announcement,
    applications,
    authentication,
    contacts,
    contents,
    contentcategories,
    contenthascategories,
    customerlicenses,
    customers,
    durationdiscounts,
    globalsnackbar,
    licensehasmodules,
    modules,
    quantitydiscounts,
    subcustomers,
    templatemodules,
    templates,
    users,
    profile,
    termsofservices,
    termsofserviceagreements,
    password,
    messages,
    messaginglists,
    messaginglistmembers,
    userlogins,
    topics,
    messagereads,
    issues,
    project
  },
});
