<template>
  <div>
    <!--<span class="bg"></span>-->
    <div id="image_wrapper">
      <img :src="require('@/assets/bg.jpg')">
    </div>

    <v-overlay
      :value="overlay"
      :opacity="0.5"
      class="login_overlay"
    >
      <v-card rounded>
        <v-card-title>
          <v-spacer></v-spacer>
          <span>COBISOFT Partnerportal Login</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                tabindex="1"
                label="Email"
                required
                outlined
                v-model="name"
                hide-details
                :prependIcon="emailIcon"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                tabindex="2"
                label="Password"
                required
                outlined
                v-model="password"
                hide-details
                :prependIcon="passwordIcon"
                :appendIcon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @keydown.enter="login"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            width="40%"
            tabindex="3"
            outlined
            @click="login"
          >
            <v-icon left>mdi-login</v-icon>
            <span>Login</span>
          </v-btn>
          <v-btn
            width="40%"
            tabindex="3"
            outlined
            @click="$router.push('/resetpassword')"
          >
            <v-icon left>mdi-lock-reset</v-icon>
            <span>Reset Password</span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-snackbar
        v-model="warning"
        timeout="3000000"
        bottom
        outlined
        color="#e84141"
      >
        <span>{{ warningMessage }}</span>
        <template v-slot:action="{attrs}">
          <v-btn
            color="#e84141"
            outlined
            v-bind="attrs"
            @click="warning = false"
          >
            <span>Okay</span>
          </v-btn>
        </template>
      </v-snackbar>
    </v-overlay>
  </div>
</template>
<script>
export default {
  name: "Login",
  data: () => ({
    overlay: true,
    name: "",
    emailIcon: "mdi-account",
    password: "",
    passwordIcon: "mdi-lock",
    showPassword: false,
    warning: false,
    warningMessage: "",
  }),
  mounted() {
    console.log("Env-var: " + process.env.VUE_APP_API_HOST);
    this.$emit("hide-toolbar");
  },
  methods: {
    resetPassword: function () {
      console.log("Do password reset stuff");
    },
    login: function () {
      if (!this.name || !this.password) {
        this.warningMessage =
          '"Username / Email" and "Password" are required to login.';
        this.warning = true;
        return;
      }

      this.$axios
        .post("/login", { email: this.name, password: this.password })
        .then((response) => {
          const token = response.data.token;
          const user = response.data.user;

          localStorage.setItem("jwt", token);
          this.$store.dispatch("login", { user, token });
          this.$emit("show-toolbar");
          this.$nextTick(() => {
            this.$axios.defaults.headers.common["token"] = token;
            this.$nextTick(() => {
              if (this.$route.query.redirect) {
                this.$emit("reload");
                this.$router.push({ path: this.$route.query.redirect });
              } else {
                var jwt_module = require("../../helpers/jwt");
                var payload = jwt_module.decode(token).payload;

                if (payload.is_admin || payload.is_manager) {
                  this.$emit("reload");
                  this.$router.push({ path: "/admin/partners" });
                } else {
                  this.$emit("reload");
                  this.$router.push({ path: "/user/dashboard" });
                }
              }
            });
          });
        })
        .catch((err) => {
          console.log(err);
          this.warningMessage =
            "Unable to login. Please check your " +
            "username and password and try again";
          this.warning = true;
        });
    },
  },
};
</script>
<style scoped>
#image_wrapper,
img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.login_overlay {
  backdrop-filter: blur(40px);
}
</style>