import axios from "axios";
import Vue from "vue";

const state = {
  quantitydiscountsFetched: false,
  lastFetch: 0,
  newQuantitydiscount: {
    templateid: null,
    amount: 0,
    discount: "0.00",
  },
  quantitydiscounts: [
    // {
    //   templateid: 1,
    //   amount: 5,
    //   discount: "5.00",
    // },
    // {
    //   templateid: 1,
    //   amount: 10,
    //   discount: "10.00",
    // },
    // {
    //   templateid: 1,
    //   amount: 20,
    //   discount: "20.00",
    // },
    // {
    //   templateid: 2,
    //   amount: 10,
    //   discount: "5.00",
    // },
    // {
    //   templateid: 2,
    //   amount: 20,
    //   discount: "10.00",
    // },
    // {
    //   templateid: 3,
    //   amount: 5,
    //   discount: "5.00",
    // },
    // {
    //   templateid: 3,
    //   amount: 10,
    //   discount: "10.00",
    // },
    // {
    //   templateid: 3,
    //   amount: 20,
    //   discount: "20.00",
    // },
    // {
    //   templateid: 4,
    //   amount: 10,
    //   discount: "5.00",
    // },
    // {
    //   templateid: 4,
    //   amount: 20,
    //   discount: "10.00",
    // },
    // {
    //   templateid: 5,
    //   amount: 5,
    //   discount: "5.00",
    // },
    // {
    //   templateid: 5,
    //   amount: 10,
    //   discount: "10.00",
    // },
    // {
    //   templateid: 5,
    //   amount: 20,
    //   discount: "20.00",
    // },
    // {
    //   templateid: 6,
    //   amount: 5,
    //   discount: "5.00",
    // },
    // {
    //   templateid: 6,
    //   amount: 10,
    //   discount: "10.00",
    // },
    // {
    //   templateid: 6,
    //   amount: 20,
    //   discount: "20.00",
    // },
  ],
};

const getters = {
  newQuantitydiscount: (state) => state.newQuantitydiscount,
  allQuantitydiscounts: (state) => state.quantitydiscounts,
  quantitydiscountsByTemplateId: (state) => (templateid) => {
    return state.quantitydiscounts.filter((x) => x.templateid == templateid);
  },
  quantitydiscountByTemplateIdAndAmount: (state) => (templateid, amount) => {
    var discounts = state.quantitydiscounts.filter(
      (x) => x.templateid == templateid
    );

    if (!discounts) {
      discounts = [
        {
          templateid: templateid,
          amount: 0,
          discount: "0.00",
        },
      ];
    }

    var highest = {
      templateid: templateid,
      amount: 0,
      discount: "0.00",
    };

    discounts.forEach((discount) => {
      if (
        discount.amount <= amount &&
        parseFloat(discount.discount) >= parseFloat(highest.discount)
      ) {
        highest = discount;
      }
    });

    return highest;
  },
};

const actions = {
  fetchQuantitydiscounts({ commit }) {
    Vue.prototype.$axios.get("/quantitydiscounts").then((response) => {
      commit("SET_QUANTITYDISCOUNTS", response.data);
    });
  },
  fetchQuantitydiscountsAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return;
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/quantitydiscounts")
        .then((response) => {
          commit("SET_QUANTITYDISCOUNTS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async postQuantitydiscount({ commit }, discount) {
    try {
      var response = await Vue.prototype.$axios.post(
        "/quantitydiscounts",
        discount
      );
      commit("ADD_QUANTITYDISCOUNT", discount);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
};

const mutations = {
  SET_QUANTITYDISCOUNTS(state, quantitydiscounts) {
    state.quantitydiscounts = quantitydiscounts;
    state.quantitydiscountsFetched = true;
  },
  ADD_QUANTITYDISCOUNT(state, discount) {
    state.quantitydiscounts.push(discount);
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
