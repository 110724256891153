<template>
  <v-app class="portal">
    <v-app-bar v-if="!hideToolbar" app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-container>
        <v-img contain src="@/assets/cobisoft.svg" height="40"> </v-img>
      </v-container>

      <!-- <v-badge
        avatar
        bordered
        overlap
        color="#b31616"
        offset-x="20"
        offset-y="20"
        style="cursor: pointer"
        :content="numberOfUnreadMessages"
        :value="numberOfUnreadMessages"
      > -->
      <!-- TODO Messages -->
      <!-- <v-btn icon @click="gotoMessages()">
        <v-icon>mdi-email-outline</v-icon>
      </v-btn> -->
      <!-- </v-badge> -->

      <v-menu offset-y open-on-hover transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-on="on" v-bind="attrs">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link @click="gotoProfile">
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-if="!hideToolbar"
      v-model="drawer"
      dark
      app
      disable-route-watcher
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">COBISOFT</v-list-item-title>
          <v-list-item-subtitle>Partnerportal</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <template>
          <div v-for="item in navbarItems" :key="item.title">
            <v-list-group
              :group="item.urlcontains"
              v-if="item.items"
              v-model="item.active"
              :prepend-icon="item.icon"
              no-action
              link
              active-class="activeItemBackground"
              :disabled="item.disabled"
            >
              <template v-slot:activator>
                <v-list-item-content active-class="activeItemBackground">
                  <v-list-item-title>
                    <span :class="item.disabled ? 'disabled_class' : null">
                      {{ item.title }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="subItem in item.items"
                :key="subItem.title"
                :to="subItem.route"
                active-class="activeItemBackground"
                @click="drawer = false"
                align="left"
                class="pl-10"
              >
                <v-list-item-icon v-if="subItem.icon">
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span :class="item.disabled ? 'disabled_class' : null">
                      {{ subItem.title }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-else
              :to="item.route"
              link
              active-class="activeItemBackground"
              @click="drawer = false"
              :disabled="item.disabled"
            >
              <v-list-item-icon v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
                <v-badge
                  v-if="item.title == 'Announcements'"
                  color="primary"
                  :content="numNewAnnouncements"
                  :value="numNewAnnouncements"
                >
                </v-badge>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <span :class="item.disabled ? 'disabled_class' : null">
                    {{ item.title }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </template>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn v-if="isPrivileged" block outlined @click="changeView">
            <v-icon>mdi-account-switch-outline</v-icon>
            <span>&nbsp;Switch View</span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view
        v-if="showComponent"
        @reload="reRenderComponent"
        @hide-toolbar="
          drawer = false;
          hideToolbar = true;
        "
        @show-toolbar="
          hideToolbar = false;
          drawer = true;
        "
      ></router-view>
    </v-main>

    <!-- <snackbar></snackbar> -->
    <v-snackbar v-model="snack.show" :timeout="snack.timeout">
      {{ snack.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="hideSnackbar()">
          <span>Close</span>
        </v-btn>
      </template>
    </v-snackbar>

    <v-footer v-if="!hideToolbar" padless dark>
      <v-row justify="center" no-gutters>
        <v-btn class="ma-1" rounded text @click="imprintDialog = true"
          >Imprint</v-btn
        >
        <v-btn class="ma-1" rounded text @click="dataProtectionDialog = true"
          >Data Protection</v-btn
        >
        <v-btn
          class="ma-1"
          rounded
          text
          href="http://cobisoft.de"
          target="_blank"
          >Homepage</v-btn
        >
        <v-btn
          class="ma-1"
          rounded
          icon
          href="https://www.linkedin.com/company/compnetgmbh/"
          target="_blank"
        >
          <v-icon>mdi-linkedin</v-icon>
        </v-btn>
        <v-btn
          class="ma-1"
          rounded
          icon
          href="https://www.youtube.com/channel/UC9Of8qHIjy9mYheia-Hgr1g"
          target="_blank"
        >
          <v-icon>mdi-youtube</v-icon>
        </v-btn>
        <v-col cols="12" class="text-center">
          <small>{{ getCopyrightText() }}</small>
        </v-col>
      </v-row>
    </v-footer>

    <data-protection-dialog
      v-model="dataProtectionDialog"
    ></data-protection-dialog>

    <imprint-dialog v-model="imprintDialog"></imprint-dialog>

    <tos-dialog
      v-if="tosDialog"
      v-model="tosDialog"
      :tos="latestTos"
    ></tos-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ToolbarItem from "@/components/ToolbarItem";
import tosDialog from "./components/app_termsofservice_dialog.vue";
import dataProtectionDialog from "./components/app_dataprotection_dialog.vue";
import imprintDialog from "./components/app_imprint_dialog.vue";
import { verify, decode } from "./helpers/jwt";
// import { snackbar } from "./components/helpers/snackbar.vue";
export default {
  name: "App",
  components: {
    ToolbarItem,
    dataProtectionDialog,
    imprintDialog,
    tosDialog,
    // snackbar,
  },
  computed: {
    ...mapGetters({
      snack: "getSnackbar",
      allTermsofservices: "allTermsofservices",
      newTermsofservice: "newTermsofservice",
      allTermsofserviceagreements: "allTermsofserviceagreements",
      newTermsofserviceagreement: "newTermsofserviceagreement",
      userloginsByUserid: "userloginsByUserid",
      getAllAnnouncements: "getAllAnnouncements",
      numberOfUnreadMessages: "numberOfUnreadMessages",
      profile: "profile",
    }),
    navbarItems: {
      get() {
        var route = this.$route.path;
        //console.log(route);
        if (route.includes("/admin")) {
          return this.navbarItemsAdmin;
        } else if (route.includes("/user")) {
          console.log(this.profile);
          const title = "Support";
          if (
            this.profile &&
            this.profile.projectid &&
            this.profile.projectid_string &&
            this.navbarItemsUser.filter((e) => e.title == title).length == 0
          ) {
            const prices = this.navbarItemsUser.pop();
            this.navbarItemsUser.push({
              title: title,
              icon: "mdi-ticket-confirmation",
              route: "/user/issues",
            });
            this.navbarItemsUser.push(prices);
          }
          return this.navbarItemsUser;
        } else if (route.includes("/playground")) {
          return this.navbarItemsPlayground;
        } else {
          return this.navbarItemsUser;
        }
      },
    },
    profileItem: {
      get() {
        var route = this.$route.path;
        if (route.includes("/admin")) {
          return "/admin/profile";
        } else if (route.includes("/user")) {
          return "/user/profile";
        } else {
          return "/user/profile";
        }
      },
    },
    isPrivileged: function () {
      if (verify(localStorage.getItem("jwt"))) {
        let user = decode(localStorage.getItem("jwt"));
        if (user.payload.is_admin || user.payload.is_manager) return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      hideSnackbar: "hideSnackbar",

      fetchTermsofservicesAsync: "fetchTermsofservicesAsync",
      fetchTermsofserviceagreementsAsync: "fetchTermsofserviceagreementsAsync",
      testTermsofserviceagreement: "testTermsofserviceagreement",
      fetchAllAnnouncements: "fetchAllAnnouncements",
      fetchUserloginsAsync: "fetchUserloginsAsync",
      fetchUnreadMessagesOfMessagingList: "fetchUnreadMessagesOfMessagingList",
      fetchProfileAsync: "fetchProfileAsync",
    }),
    /* numberOfNewAnnouncements() {
      if (localStorage.getItem("jwt")) {
        let userid = decode(localStorage.getItem("jwt")).payload.id;
        let loginlist = this.userloginsByUserid(userid);
        let lastLogin = loginlist[1].timestamp;
        return this.getAllAnnouncements.reduce(
          (a, b) =>
            new Date(b.creationdate).getTime() > lastLogin ? (a = a + 1) : a,
          0
        );
      }
    }, */
    changeView: function () {
      var route = this.$route.path;
      if (route.includes("/admin")) {
        this.$router.push({ path: "/user" });
      } else if (route.includes("/user")) {
        this.$router.push({ path: "/admin" });
      } else if (route.includes("/playground")) {
        this.$router.push({ path: "/admin" });
      }
    },
    gotoMessages: function () {
      var route = this.$route.path;
      if (route.includes("/admin")) {
        this.$router.push({ path: "/admin/messages" });
      } else if (route.includes("/user")) {
        this.$router.push({ path: "/user/messages/messages" });
      } else {
        this.$router.push({ path: "/user/messages/messages" });
      }
    },
    gotoProfile: function () {
      var route = this.$route.path;
      if (route.includes("/admin")) {
        this.$router.push({ path: "/admin/profile" });
      } else if (route.includes("/user")) {
        this.$router.push({ path: "/user/profile" });
      } else {
        this.$router.push({ path: "/user/profile" });
      }
    },
    logout: function () {
      localStorage.removeItem("jwt");
      this.$router.push({ path: "/login" });
    },
    reRenderComponent: function () {
      /*  
        This is to avoid a 401 response from the api as the token
        would not have been set when each view components asyncronous mounted
        function gets called after logging in
      */
      this.$forceUpdate();
    },
    getCopyrightText: function () {
      var ctxt = "Copyright © ";
      var d = new Date();
      ctxt += d.getFullYear();
      ctxt += " COBISOFT. All Rights Reserved";
      return ctxt;
    },
  },
  data: () => ({
    numNewAnnouncements: 0,
    group: null,
    drawer: false,
    hideToolbar: false,
    showComponent: true,
    dataProtectionDialog: false,
    imprintDialog: false,
    tosDialog: false,
    latestTos: null,
    tab: "",
    /*
    Menu items should look like this:
      regular-item:
      {
        title: string,
        icon: string,
        route: string
      }
      item-group:
      {
        title: string,
        icon: string (optional)
        urlcontains: string,
        items: Array(<regular-item>)
      }
      <
    */
    navbarItemsUser: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        route: "/user/dashboard",
      },
      {
        title: "Customers",
        icon: "mdi-account-cash",
        route: "/user/customers",
      },

      {
        title: "Brochures",
        icon: "mdi-book-information-variant",
        route: "/user/marketingresources/brochures",
      },
      {
        title: "Documentations",
        icon: "mdi-book-education",
        route: "/user/marketingresources/documentations",
      },
      {
        title: "Announcements",
        icon: "mdi-bullhorn-variant",
        route: "/user/announcements",
      },
      /*       {
        title: "Messages",
        icon: "mdi-message-text-outline",
        route: "/user/messages/messages",
      }, */
      {
            title: "Pricelists",
            icon: "mdi-book-information-variant",
            route: "/user/marketingresources/pricelists/current",
          },
      /* {
        title: "Pricelists",
        urlcontains: "/user/marketingresources/pricelists/",
        disabled: false,
        items: [
          {
            title: "Pricelists",
            icon: "mdi-book-information-variant",
            route: "/user/marketingresources/pricelists/current",
          },          {
            title: "Upcoming(2024)",
            icon: "mdi-book-information-variant",
            route: "/user/marketingresources/pricelists/2024",
          }, 
        ],
      }, */
    ],
    navbarItemsPlayground: [
      {
        title: "Testing 1",
        icon: "mdi-bug-outline",
        route: "/playground/testing1",
      },
      {
        title: "Testing 2",
        icon: "mdi-bug-outline",
        route: "/playground/testing2",
      },
    ],
    navbarItemsAdmin: [
      {
        title: "History",
        icon: "mdi-history",
        route: "/admin/history",
      },
      {
        title: "Requests",
        icon: "mdi-phone-incoming",
        route: "/admin/requests",
      },
      {
        title: "Partners",
        icon: "mdi-account-cash",
        route: "/admin/partners",
      },
      {
        title: "Users",
        icon: "mdi-account",
        route: "/admin/users",
      },
      {
        title: "License Settings",
        icon: "mdi-file-document-edit",
        route: "/admin/licensesettings",
      },
      {
        title: "Announcement Editor",
        icon: "mdi-cog-outline",
        route: "/admin/announcements",
      },
      /*       {
        title: "Messages",
        icon: "mdi-message-text-outline",
        route: "/admin/messages",
      }, */
      /*
      {
        title: "Training",
        urlcontains: "/admin/training/",
        disabled: true,
        items: [
          {
            title: "Schedule",
            icon: "mdi-calendar-month",
            route: "/admin/training/schedule",
          },
          {
            title: "Booking",
            icon: "mdi-calendar-plus",
            route: "/admin/training/booking",
          },
        ],
      },
      */

      {
        title: "Brochures",
        icon: "mdi-book-information-variant",
        route: "/admin/marketingresources/brochures",
      },
      {
        title: "Documentations",
        icon: "mdi-book-education",
        route: "/admin/marketingresources/documentations",
      },
      {
            title: "Pricelists",
            icon: "mdi-book-information-variant",
            route: "/admin/marketingresources/pricelists/current",
          },
      /* {
        title: "Pricelists",
        urlcontains: "/admin/marketingresources/pricelists/",
        disabled: false,
        items: [
          {
            title: "Pricelists",
            icon: "mdi-book-information-variant",
            route: "/admin/marketingresources/pricelists/current",
          },
                     {
            title: "Upcoming(2024)",
            icon: "mdi-book-information-variant",
            route: "/admin/marketingresources/pricelists/2024",
          }, 
        ],
      }, */
      /*
      {
        title: "News",
        icon: "mdi-newspaper",
        route: "/admin/news",
      },
      {
        title: "Upload",
        icon: "mdi-cloud-upload",
        route: "/admin/upload",
      },
      {
        title: "Settings",
        icon: "mdi-account-cog",
        route: "/admin/settings",
      },
      {
        title: "User Settings",
        icon: "mdi-account-cog-outline",
        route: "/admin/usersettings",
      },
      */
    ],
  }),
  async mounted() {
    console.log(require("./helpers/logoprinter").getLogo());
    require("./helpers/test").printString();
    if (!localStorage.getItem("jwt")) return;
    await this.fetchTermsofservicesAsync(); // needed?? what about admin
    await this.fetchTermsofserviceagreementsAsync();
    await this.fetchProfileAsync();

    //await this.fetchMessagesAsync();

    //await this.fetchUserloginsAsync();
    //this.fetchAllAnnouncements();

    //console.log("debug:");
    //console.log(this.allTermsofservices);
    //console.log(this.allTermsofserviceagreements);

    //this.fetchUnreadMessagesOfMessagingList()
  },
};
</script>
<style scoped>
.disabled_class {
  color: #a0a0a0;
}
.activeItemBackground {
  color: #1976d2 !important;
}
.blurredOverlay {
  backdrop-filter: blur(10px);
}
.portal {
  font-size: 22px !important;
}
.cobisofttitle {
  font-size: 1.95rem !important;
  font-family: Calibri !important;
  color: #97979b;
}
.mouseover {
  cursor: pointer !important;
}
</style>
