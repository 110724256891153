import axios from "axios";
import Vue from "vue";

const state = {
  termsofservicesFetches: false,
  lastFetched: 0,
  newTermsofservice: {
    id: null,
    filename: "",
    version: "",
    apipath: "",
    language: "",
    date: "",
  },
  termsofservices: [],
};

const getters = {
  allTermsofservices: (state) => state.termsofservices,
  newTermsofservice: (state) =>
    JSON.parse(JSON.stringify(state.newTermsofservice)),
};

const actions = {
  fetchTermsofservicesAsync({ commit, state }) {
    if (state.lastFetched + 2000 < Date.now()) {
      state.lastFetched = Date.now();
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/termsofservices")
        .then((response) => {
          commit("SET_TERMSOFSERVICES", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postTermsofservice({ commit }, termsofservice) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios.post("/termsofservices");
    });
  },
};

const mutations = {
  SET_TERMSOFSERVICES(state, termsofservice) {
    state.termsofservices = termsofservice;
    state.termsofservicesFetches = true;
  },
  ADD_TERMSOFSERVICE(state, termsofservice) {
    state.termsofservices.push(termsofservice);
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
