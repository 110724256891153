import axios from 'axios'

const state = {
  someArrayOfObjects: [
    {

    }
  ]
}

const getters = {

}

const actions = {

}

const mutations = {

}

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}