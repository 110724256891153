import axios from "axios";
import Vue from "vue";

const state = {
  templatesFetched: false,
  lastFetch: 0,
  newTemplate: {
    id: null,
    name: "",
    description: "",
    applicationid: "",
    price: "",
    monthlyprice: 0.0,
    freenfr: 0,
    partnerdiscount: 0.0,
    partnermaintenancefee: 0,
    createdby: null,
  },
  templates: [
    // {
    //   id: 1,
    //   name: "COBI.wms (Full)",
    //   description: "Full license containing all functionalities",
    //   applicationid: "cwms",
    //   price: "225.00",
    //   monthlyprice: null,
    //   freenfr: 1,
    //   partnerdiscount: 20.0,
    //   partnermaintenancefee: 10.0,
    //   createdby: 1,
    // },
    // {
    //   id: 2,
    //   name: "COBI.wms (Basic)",
    //   description: "Basic license containing only COBI.wms itself",
    //   applicationid: "cwms",
    //   price: "100.00",
    //   monthlyprice: null,
    //   freenfr: 5,
    //   partnerdiscount: 20.0,
    //   partnermaintenancefee: 10.0,
    //   createdby: 1,
    // },
    // {
    //   id: 3,
    //   name: "COBI.time (Full)",
    //   description: "Full license containing all functionalities",
    //   applicationid: "ctime",
    //   price: "200.00",
    //   monthlyprice: "30.00",
    //   freenfr: 2,
    //   partnerdiscount: 20.0,
    //   partnermaintenancefee: 10.0,
    //   createdby: 1,
    // },
    // {
    //   id: 4,
    //   name: "COBI.time (Basic)",
    //   description: "Basic license containing only COBI.time itself",
    //   applicationid: "ctime",
    //   price: "150.00",
    //   monthlyprice: "20.00",
    //   freenfr: 5,
    //   partnerdiscount: 20.0,
    //   partnermaintenancefee: 10.0,
    //   createdby: 1,
    // },
    // {
    //   id: 5,
    //   name: "COBI.ppc (Full)",
    //   description: "Full license containing all functionalities",
    //   applicationid: "cppc",
    //   price: "500.00",
    //   monthlyprice: null,
    //   freenfr: 2,
    //   partnerdiscount: 20.0,
    //   partnermaintenancefee: 20.0,
    //   createdby: 1,
    // },
    // {
    //   id: 6,
    //   name: "COBI.shipping (Full)",
    //   description: "Full license containing all functionalities",
    //   applicationid: "cship",
    //   price: "350.00",
    //   monthlyprice: null,
    //   freenfr: 2,
    //   partnerdiscount: 20.0,
    //   partnermaintenancefee: 20.0,
    //   createdby: 1,
    // },
  ],
};

const getters = {
  allTemplates: (state) => state.templates,
  allVisibleTemplates: (state) => state.templates.filter(item => item.visible),
  newTemplate: (state) => JSON.parse(JSON.stringify(state.newTemplate)),
  templatesByApplicationId: (state) => (applicationid) => {
    return state.templates.filter((x) => x.applicationid == applicationid && x.visible);
  },
  templateById: (state) => (templateid) => state.templates.find((x) => x.id == templateid),
};

const actions = {
  fetchTemplates({ commit }) {
    Vue.prototype.$axios.get("/licensetemplates").then((response) => {
      commit("SET_TEMPLATES", response.data);
    });
  },
  fetchTemplatesAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/licensetemplates")
        .then((response) => {
          commit("SET_TEMPLATES", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postTemplate({ commit }, licensetemplate) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .post("/licensetemplates", licensetemplate)
        .then((response) => {
          commit("ADD_TEMPLATE", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          console.log("Error while posting");
          console.log(error);
          reject(error.response.data[0].message);
        });
    });
  },
  updateLicenseTemplate({ commit }, licensetemplate) {
    return Vue.prototype.$axios
      .patch("/licensetemplates", {
        id: licensetemplate.id,
        visible: licensetemplate.visible ? true : false,
      })
      .then((response) => {
        commit("UPDATE_TEMPLATE", licensetemplate);
      })
      .catch((error) => error.response.data);
  },
  deleteTemplate({ commit }, licensetemplateid) {
    return Vue.prototype.$axios
      .delete("/licensetemplates", { data: { "id": licensetemplateid } })
      .then((response) => {
        commit("DELETE_TEMPLATE", licensetemplateid);
      })
      .catch((error) => error.response.data);
  }
};

const mutations = {
  SET_TEMPLATES(state, templates) {
    state.templates = templates;
    state.templatesFetched = true;
  },
  ADD_TEMPLATE(state, template) {
    state.templates.push(template);
  },
  DELETE_TEMPLATE(state, id) {
    var idx = state.templates.findIndex((x) => x.id == id);
    state.templates.splice(idx, 1);
  },
  UPDATE_TEMPLATE(state, template) {
    state.templates = state.templates.map(e => e.id == template.id ? template : e);
  }
};
export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
