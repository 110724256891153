import axios from "axios";
import Vue from "vue";

const state = {
    messagesFetches: false,

    unreadMessages: [],

    messagereads: [],

    messagereadsLastFetch: 0,

    newMessageread: {
        id: null,
        messageid: null,
        userid: null,
    },
};

const getters = {
    allMessagereads: (state) => state.messagereads,
    newMessageread: (state) => JSON.parse(JSON.stringify(state.newMessageread)),
    unreadMessagesOfChat: (state) => (groupid) => {
        if (state.unreadMessages.length > 0) {
            const result = state.unreadMessages.find(x => x.groupid == groupid)
            return result ? result : { groupid: groupid, mids: [] }
        }
        return { groupid: groupid, mids: [] }
    },
    numberOfUnreadMessages: (state) => {
        if (state.unreadMessages.length > 0) {
            return state.unreadMessages.reduce((a, b) => a + b.mids.length, 0)
        }
        return 0;
    }
};

const actions = {
    fetchUnreadMessagesOfMessagingList({ commit }) {
        return Vue.prototype.$axios.get("messagereads")
            .then((response) => {
                const mappedList = [];
                response.data.forEach(x => {
                    const index = mappedList.findIndex(el => x.messaginglistid == el.groupid);
                    if (index == -1) {
                        mappedList.push({
                            groupid: x.messaginglistid,
                            mids: [x.mesid]
                        })
                    } else {
                        mappedList[index].mids.push(x.mesid);
                    }
                })
                commit("SET_UNREAD_MESSAGES", mappedList);
            })
            .catch((error) => {
                return "Could not fetch all unread messages";
            });
    },
    changeAllUnreadMessagesOfUserInGroup({ commit }, useridAndMessagids) {
        return Vue.prototype.$axios.post("messagereads", { "mids": useridAndMessagids.mids, "userid": useridAndMessagids.userid })
            .then((response) => {
                commit("SET_UNREAD_MESSAGES", 0);
            })
            .catch((error) => "Could not change messages to read");
    },
};

const mutations = {
    //Messagereads
    SET_MESSAGEREADS(state, messagereads) {
        state.messagereads = messagereads;
    },
    SET_UNREAD_MESSAGES(state, data) {
        state.unreadMessages = data;
    },
};

export default {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
