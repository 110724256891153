import axios from "axios";
import { _ } from "core-js";
import Vue from "vue";

const state = {
  customersFetched: false,
  lastFetch: 0,
  customersSortKey: "customerid",
  customersSortDirection: "asc",
  newCustomer: {
    city: "",
    contact: null,
    contactname: "",
    country: "",
    createdby: 0,
    creationdate: "",
    customerid: 0,
    email: "",
    hnumber: "",
    loginname: null,
    loginpassword: null,
    name: "",
    notes: "",
    state: "",
    street: "",
    tel: "",
    zip: "",
    projectid: null,
    projectid_string: null,
  },
  customers: [
    // {
    //   city: "Gießen",
    //   contact: null,
    //   contactname: "Sample Customer Contact 1",
    //   country: "Germany",
    //   createdby: 1,
    //   creationdate: "1616491060422",
    //   customerid: 1,
    //   email: "customer1@sample.de",
    //   hnumber: "160",
    //   loginname: null,
    //   loginpassword: null,
    //   name: "Sample Customer 1 GmbH",
    //   notes: "This is a customer example",
    //   state: "Hessen",
    //   street: "Grünberger Straße",
    //   tel: "0123098765",
    //   zip: "35394",
    //   projectid: 322,
    //   projectid_string: "Sample_Customer_Contact_1",
    // },
    // {
    //   city: "Gießen",
    //   contact: "null",
    //   contactname: "Sample Customer Contact 2",
    //   country: "Germany",
    //   createdby: 1,
    //   creationdate: "1616491060422",
    //   customerid: 2,
    //   email: "customer2@sample.de",
    //   hnumber: "160",
    //   loginname: null,
    //   loginpassword: null,
    //   name: "Sample Customer 2 GmbH",
    //   notes: "This is another customer example",
    //   state: "Hessen",
    //   street: "Grünberger Straße",
    //   tel: "0123098765",
    //   zip: "35415",
    //   projectid: 321,
    //   projectid_string: "Sample_Customer_2_GmbH",
    // },
  ],
};

const getters = {
  allCustomers: (state) => state.customers,
  newCustomer: (state) => state.newCustomer,
  customerById: (state) => (customerid) => {
    return state.customers.find((x) => x.customerid == customerid) ?? null;
  },
};

const actions = {
  fetchCustomers({ commit }) {
    Vue.prototype.$axios.get("/customers").then((response) => {
      commit("SET_CUSTOMERS", response.data);
    });
  },
  fetchCustomersAsync({ commit }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return;
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/customers")
        .then((response) => {
          commit("SET_CUSTOMERS", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async postCustomer({ commit }, customer) {
    try {
      var response = await Vue.prototype.$axios.post("/customers", customer);
      commit("ADD_CUSTOMER", response.data);
      return response.data;
    } catch (exception) {
      console.log(exception.response);
      // return exception.response.data;
    }
  },
  async patchCustomer({ commit }, customer) {
    try {
      var response = await Vue.prototype.$axios.patch("/customers", customer);
      commit("UPDATE_CUSTOMER", customer);
    } catch (exception) {
      return exception.response.data;
    }
  },
  async deleteCustomer({ commit }, customer) {
    try {
      var response = await Vue.prototype.$axios.delete("/customers", {
        data: customer,
      });
      commit("DELETE_CUSTOMER", customer);
    } catch (exception) {
      console.log(exception.response.data);
      return exception.response.data;
    }
  },
  setProjectIdsForCustomer({ commit }, customer) {
    commit("UPDATE_CUSTOMER", customer);
  }
  /*
  sortCustomers({ commit }, { sortKey, sortDirection }) {
    commit("SET_SORT_KEY", sortKey);
    commit("SET_SORT_DIRECTION", sortDirection);
    commit("SORT_CUSTOMERS");
  },
  */
};

const mutations = {
  SET_CUSTOMERS(state, customers) {
    state.customers = customers;
    state.customersFetched = true;
  },
  ADD_CUSTOMER(state, customer) {
    state.customers.push(customer);
  },
  UPDATE_CUSTOMER(state, customer) {
    var idx = state.customers.findIndex(
      (x) => x.customerid == customer.customerid
    );
    Vue.set(state.customers, idx, customer);
  },
  DELETE_CUSTOMER(state, customer) {
    var idx = state.customers.findIndex(
      (x) => x.customerid == customer.customerid
    );
    state.customers.splice(idx, 1);
  },
  /*
  SET_SORT_KEY(state, sortKey) {
    if (Object.keys(state.newCustomer).includes(sortKey)) {
      state.customersSortKey = sortKey;
      console.log("Set sortKey to: {0}".format(sortKey));
    }
  },
  SET_SORT_DIRECTION(state, sortDirection) {
    if (["asc", "desc"].includes(sortDirection)) {
      state.customersSortDirection = sortDirection;
      console.log("Set sortDirection to: {0}".format(sortDirection));
    }
  },
  SORT_CUSTOMERS(state) {
    const tmp_customers = this.state.customers.customers;
    const key = this.state.customers.customersSortKey;
    const direction = this.state.customers.customersSortDirection;

    console.log("Tmp Customers");
    console.log(tmp_customers);

    tmp_customers.sort((a, b) => {
      let compare = 0;

      if (a[key] > b[key]) compare = 1;
      else if (b[key] > a[key]) compare = -1;

      if (direction === "desc") compare *= -1;

      return compare;
    });
    state.customers = tmp_customers;
  },
  */
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
