import axios from "axios";
import Vue from "vue";

const state = {
  contenthascategoriesFetched: false,
  newContentcategory: {
    contentid: null,
    contentcategoryid: null,
  },
  contenthascategories: [
    // {
    //   contentid: 0,
    //   contentcategoryid: 0,
    // },
  ],
};

const getters = {
  allContenthascategories: (state) => state.contenthascategories,
  newContenthascategory: (state) =>
    JSON.parse(JSON.stringify(state.newContentcategory)),
  contenthascategoriesByContentid: (state) => (contentid) => {
    return state.contenthascategories.filter((x) => x.contentid == contentid);
  },
  contenthascategoriesByContentcategoryid: (state) => (contentcategoryid) => {
    return state.contenthascategories.filter(
      (x) => x.contentcategoryid == contentcategoryid
    );
  },
};

const actions = {
  fetchContenthascategories({ commit }) {
    Vue.prototype.$axios.get("/contenthascategories").then((response) => {
      commit("SET_CONTENTHASCATEGORIES", response.data);
    });
  },
  fetchContenthascategoriesAsync({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/contenthascategories")
        .then((response) => {
          commit("SET_CONTENTHASCATEGORIES", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async postContenthascategorie({ commit }, contenthascategory) {
    try {
      var response = await Vue.prototype.$axios.post(
        "/contenthascategories",
        contenthascategory
      );
      commit("ADD_CONTENTHASCATEGORY", response.data);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
  async deleteContenthascategorie({ commit }, contenthascategory) {
    try {
      var response = await Vue.prototype.$axios.delete(
        "/contenthascategories",
        contenthascategory
      );
      commit("DELETE_CONTENTHASCATEGORY", response.data);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
};

const mutations = {
  SET_CONTENTHASCATEGORIES(state, contenthascategories) {
    state.contenthascategories = contenthascategories;
    state.contenthascategoriesFetched = true;
  },
  ADD_CONTENTHASCATEGORY(state, contenthascategory) {
    state.contenthascategories.push(contenthascategory);
  },
  DELETE_CONTENTHASCATEGORY(state, contenthascategory) {
    var idx = state.contenthascategories.findIndex(
      (x) =>
        x.contentid == contenthascategory.contentid &&
        x.contentcategoryid == contenthascategory.contentcategoryid
    );
    state.contenthascategories.splice(idx, 1);
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
