import axios from 'axios'

const host = process.env.VUE_APP_API_HOST

const state = {
  user: {},
  token: ""
}

const getters = {
  getUser: (state) => state.user,
  getToken: (state) => state.token,
  isLoggedIn: (state) => state.user ? true : false
}

const actions = {
  login({ commit }, {user, token}) {
    commit('setUser', user)
    commit('setToken', token)
  },
  logout({ commit }) {
    commit('deleteUser')
    commit('deleteToken')
  }
}

const mutations = {
  setUser: (state, user) => (state.user = user),
  setToken: (state, token) => (state.token = token),
  deleteUser: (state) => (state.user = null),
  deleteToken: (state) => (state.token = null)
}

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}