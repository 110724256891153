<template>
  <v-dialog
    v-model="dialog"
    width="75%"
    height="75%"
    persistent
  >
    <v-card>
      <v-card-title>
        <menu-title title="Terms of Service"></menu-title>
      </v-card-title>
      <v-card-text>
        <pdf
          v-for="i in pageCount"
          :key="i"
          :src="source"
          :page="i"
          class="mb-6"
          style="display: inline-block; width: 100%; outline: auto;"
        ></pdf>
      </v-card-text>
      <!--
      <v-card-text v-else>
        <v-skeleton-loader
          class="mx-auto"
          type="image"
        ></v-skeleton-loader>
      </v-card-text>
      -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="#b31616"
          @click="agree"
          class="mb-5"
        >
          I agree to the Terms of Service
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import pdf from "vue-pdf";
import Vue from "vue";
import menuTitle from "../components/helpers/menu_title.vue";
export default {
  name: "TosDialog",
  props: {
    value: Boolean,
    tos: {
      type: Object,
      required: true,
    },
  },
  components: {
    menuTitle,
    pdf,
  },
  data: () => ({
    isLoading: true,
    source: undefined,
    pageCount: undefined,

    currentPage: undefined,
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      postTermsofserviceagreement: "postTermsofserviceagreement",
    }),
    agree: function () {
      this.postTermsofserviceagreement(this.tos.id)
        .then(() => {
          this.dialog = false;
        })
        .catch((error) => {
          console.log("error:");
          console.log(error);
        });
    },
  },
  async mounted() {
    console.log("Mounted ToS Dialog");
    var apiBase = Vue.prototype.$axios.defaults.baseURL;
    var url = apiBase + this.tos.apipath;
    console.log("apibase:");
    console.log(apiBase);
    console.log("url");
    console.log(url);
    this.source = pdf.createLoadingTask(url);
    /*this.source = pdf.createLoadingTask(
      require("../assets/tos/tos_2022-05-16_en_1.0.pdf")
    );*/
    this.source.promise.then((pdf) => {
      this.pageCount = pdf.numPages;
      this.isLoading = false;
    });
  },
};
</script>

<style>
</style>