<template>
  <v-container
    fluid
    class="py-0"
  >
    <v-row>
      <v-col
        :cols="$slots.default ? '8' : '12'"
        align="left"
        class="nopad_class"
      >
        <v-container
          align="left"
          class="nopad_class"
        >
          <span
            align="left"
            class="title_class"
          >
            {{ title }}
          </span>
        </v-container>
      </v-col>
      <v-col
        v-if="$slots.default"
        cols="4"
        align="right"
      >
        <v-container align="right">
          <slot name="default"></slot>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "menuTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.nopad_class {
  padding-left: 0px !important;
  margin-left: 0px !important;
}
.title_class {
  font-size: 2.2rem;
  color: #b31616;
}
</style>