import Vue from "vue";

const state = {
  modulesFetched: false,
  lastFetch: 0,
  newModule: {
    moduleid: null,
    modulename: "",
    moduledescription: "",
    applicationid: "",
    internalid: "",
    price: 0.0,
    monthlyprice: 0.0,
  },
  modules: [
    // {
    //   moduleid: 1,
    //   modulename: "base",
    //   moduledescription: "COBI.wms",
    //   applicationid: "cwms",
    //   internalid: "base",
    //   price: 100.0,
    //   monthlyprice: null,
    // },
    // {
    //   moduleid: 2,
    //   modulename: "COBI.wms Extension Module 1",
    //   moduledescription: "COBI.wms Extension 1",
    //   applicationid: "cwms",
    //   internalid: "cwms_ext1",
    //   price: 50.0,
    //   monthlyprice: null,
    // },
    // {
    //   moduleid: 3,
    //   modulename: "COBI.wms Extension Module 2",
    //   moduledescription: "COBI.wms Extension 2",
    //   applicationid: "cwms",
    //   internalid: "cwms_ext2",
    //   price: 75.0,
    //   monthlyprice: null,
    // },
    // {
    //   moduleid: 4,
    //   modulename: "base",
    //   moduledescription: "COBI.time",
    //   applicationid: "ctime",
    //   internalid: "base",
    //   price: 150.0,
    //   monthlyprice: 20.0,
    // },
    // {
    //   moduleid: 5,
    //   modulename: "COBI.time Extensin Module 1",
    //   moduledescription: "COBI.time Extension 1",
    //   applicationid: "ctime",
    //   internalid: "ctime_ext1",
    //   price: 25.0,
    //   monthlyprice: 5.0,
    // },
    // {
    //   moduleid: 6,
    //   modulename: "COBI.time Extension Module 2",
    //   moduledescription: "COBI.time Extension 2",
    //   applicationid: "ctime",
    //   internalid: "ctime_ext2",
    //   price: 25.0,
    //   monthlyprice: 5.0,
    // },
    // {
    //   moduleid: 7,
    //   modulename: "base",
    //   moduledescription: "COBI.ppc",
    //   applicationid: "cppc",
    //   internalid: "base",
    //   price: 500.0,
    //   monthlyprice: null,
    // },
    // {
    //   moduleid: 8,
    //   modulename: "base",
    //   moduledescription: "COBI.shipping",
    //   applicationid: "cship",
    //   internalid: "base",
    //   price: 350.0,
    //   monthlyprice: null,
    // },
  ],
};

const getters = {
  allModules: (state) => state.modules,
  newModule: (state) => state.newModule,
  modulesByApplicationid: (state) => (applicationid) => {
    return state.modules.filter((x) => x.applicationid == applicationid);
  },
  moduleByModuleid: (state) => (moduleid) => {
    return state.modules.find((x) => x.moduleid == moduleid);
  },
};

const actions = {
  fetchModules({ commit }) {
    Vue.prototype.$axios.get("/modules").then((response) => {
      commit("SET_MODULES", response.data);
    });
  },
  fetchModulesAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return;
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/modules")
        .then((response) => {
          commit("SET_MODULES", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async postModule({ commit }, module) {
    console.log("Posting module:");
    console.log(module);
    try {
      var response = await Vue.prototype.$axios.post("/modules", module);
      commit("ADD_MODULE", response.data);
    } catch (exception) {
      var ret = exception.response.data[0].message;
      return ret;
    }
  },
  async patchModule({ commit }, module) {
    console.log("Patching Module:");
    console.log(module);

    try {
      var response = await Vue.prototype.$axios.patch("/modules", module);
      commit("UPDATE_MODULE", module);
    } catch (exception) {
      var ret = exception.response.data[0].message;
      return ret;
    }
  },
  async deleteModule({ commit }, moduleid) {
    try {
      var response = await Vue.prototype.$axios.delete("/modules", { data: { "id": moduleid } });
      commit("DELETE_MODULE", module);
    } catch (exception) {
      var ret = exception.response.data[0].message;
      return ret;
    }
  },
};

const mutations = {
  SET_MODULES(state, modules) {
    state.modules = modules;
    state.modulesFetched = true;
  },
  ADD_MODULE(state, module) {
    state.modules.push(module);
  },
  UPDATE_MODULE(state, module) {
    var m = state.modules.find((x) => x.moduleid == module.moduleid);
    Object.keys(module).forEach((key) => {
      m[key] = module[key];
    });
  },
  DELETE_MODULE(state, moduleid) {
    const index = state.modules.findIndex(x => x.moduleid == moduleid);
    state.modules.splice(index, 1)

  }
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
