import Vue from "vue";
import App from "./App.vue";
import { sync } from "vuex-router-sync";
import router from "./router";

import vuetify from "./plugins/vuetify";
import axios from "axios";
//import { createProvider } from './vue-apollo'
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";

// require("dotenv").config();

Vue.config.productionTip = false;

const apiHost = `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_API_PORT}`;
// const apiHost = "http://localhost:9641";
//const apiHost = "https://api.cobisoft.de:9641";
//const apiHost = "http://10.202.131.22:9641";

const axiosConfig = {
  baseURL: apiHost,
  headers: { token: localStorage.getItem("jwt") },
};
var axiosBase = axios.create(axiosConfig);

axiosBase.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwt");
  config.headers.token = token;
  config.headers.Authorization = token;
  return config;
});

Vue.prototype.$axios = axiosBase;
Vue.prototype.$url = apiHost;

import store from "./store";
const unsync = sync(store, router);

var mixin = {
  data: function () {
    return {};
  },
  mounted() { },
  methods: {
    addParams(params) {
      history.pushState(
        {},
        null,
        this.$route.path +
        "?" +
        Object.keys(params)
          .map((key) => {
            return (
              encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            );
          })
          .join("&")
      );
    },
    clearParams() {
      history.pushState({}, null, this.$route.path);
    },
  },
};
Vue.mixin(mixin);

// Simple C# and Java like string formatting implementation
// Equivalent to String.Format()

if (!String.prototype.format) {
  String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != "undefined" ? args[number] : match;
    });
  };
}

/*
Vue.use(Zendesk, {
  key: "f652a458-eb6f-4d46-aaec-fd1be8a46de4",
  disabled: false,
  hideOnLoad: false,
  settings: {
    webWidget: {
      color: {
        theme: "#b31616",
      },
    },
  },
});
*/

///
/// Apollo
///
/*
const httpLink = createHttpLink({
  uri: "http://localhost:4000/graphql",
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
*/

new Vue({
  router,
  store,
  vuetify,
  //apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
