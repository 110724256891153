<template>
  <v-dialog
    v-model="dialog"
    max-width="1500"
  >
    <v-card>
      <v-row>
        <v-col
          cols="12"
          align="right"
        >
          <v-tooltip left>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                right
                top
                fab
                class="mt-2 mr-2"
                outlined
                color="#b31616"
                small
                v-on="on"
                v-bind="attrs"
                @click="dialog=false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-card-text align="left">
        <br>
        <h1>Data protection officer</h1>
        <br>
        If you have any questions about the collection, processing or use of your personal data, information, correction, blocking or deletion of data or revocation of consent, please write to the company data protection officer: <br>
        Company data protection officer: Joanna Pulliam<br>
        Representative of the company’s data protection officer: Norbert Pulliam<br>
        <br>
        Grünbergerstr. 140, Gebäude 606<br>
        35394 Giessen<br>
        Tel.: +49 641 93221-14<br>
        E-Mail: datenschutz@compnetgmbh.de<br>
        <br>
        <v-divider></v-divider>
        <br>
        <h1>Data protection</h1>
        <br>
        We are very pleased that you are interested in our company. Data protection has a particularly high priority for the management of COMP.Net GmbH. In principle, the use of the COMP.Net GmbH website is possible without providing any personal data. If a data subject wishes to use our company’s special services via our website, processing of personal data may be necessary. If the processing of personal data is required and there is no legal basis for such processing, we generally obtain the consent of the data subject.<br>
        <br>
        The processing of personal data, for example the name, address, email address or telephone number of a data subject, is always carried out in accordance with the General Data Protection Regulation and in accordance with the country-specific data protection regulations applicable to COMP.Net GmbH. By means of this data protection declaration, our company would like to inform the public about the type, scope and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed about their rights by means of this data protection declaration.<br>
        <br>
        As the controller, COMP.Net GmbH has implemented numerous technical and organizational measures to ensure the most complete protection possible for the personal data processed via this website. Nevertheless, Internet-based data transmissions can generally have security gaps, so that absolute protection cannot be guaranteed. For this reason, every data subject is free to transmit personal data to us in alternative ways, for example by telephone.<br>
        <br>
        <v-divider></v-divider>
        <br>
        1. <b>Definitions</b><br>
        The data protection declaration of COMP.Net GmbH is based on the terminology used by the European directors and regulators when issuing the General Data Protection Regulation (GDPR). Our data protection declaration should be easy to read and understand for the public as well as for our customers and business partners. To ensure this, we would like to explain the terms used in advance.<br>
        <br>
        We use the following terms in this data protection declaration:<br>
        <br>
        <b>&nbsp;• a) Personal Data</b><br>
        Personal data is all information that relates to an identified or identifiable natural person (hereinafter “data subject”). A natural person is considered to be identifiable if, directly or indirectly, in particular by means of assignment to an identifier such as a name, an identification number, location data, an online identifier or one or more special characteristics, the expression of the physical, physiological, genetic, psychological, economic, cultural or social identity of this natural person can be identified.<br>
        <br>
        <b>&nbsp;• b) data subject</b><br>
        Affected person is any identified or identifiable natural person whose personal data are processed by the controller.<br>
        <br>
        <b>&nbsp;• c) processing</b><br>
        Processing is any process or series of processes carried out with or without the help of automated processes in connection with personal data such as the collection, collection, organization, ordering, storage, adaptation or modification, reading, querying, use, the disclosure by transmission, distribution or any other form of provision, comparison or linking, restriction, deletion or destruction.<br>
        <br>
        <b>&nbsp;• d) restriction of processing</b><br>
        Restriction of processing is the marking of stored personal data with the aim of restricting their future processing.<br>
        <br>
        <b>&nbsp;• e) Profiling</b><br>
        Profiling is any type of automated processing of personal data, which consists in the fact that this personal data is used to evaluate certain personal aspects that relate to a natural person, in particular to aspects related to work performance, economic situation, health, personal Analyze or predict the preferences, interests, reliability, behavior, location or change of location of this natural person.<br>
        <br>
        <b>&nbsp;• f) pseudonymization</b><br>
        Pseudonymization is the processing of personal data in such a way that the personal data can no longer be assigned to a specific data subject without the use of additional information, provided that this additional information is kept separately and is subject to technical and organizational measures that ensure that the personal data cannot be assigned to an identified or identifiable natural person.<br>
        <br>
        <b>&nbsp;• g) Controller or controller</b><br>
        The person responsible or responsible for processing is the natural or legal person, public authority, agency or other body that alone or together with others decides on the purposes and means of processing personal data. If the purposes and means of this processing are specified by Union law or the law of the Member States, the person responsible or the specific criteria for his naming can be provided according to Union law or the law of the Member States.<br>
        <br>
        <b>&nbsp;• h) processor</b><br>
        The processor is a natural or legal person, public authority, agency or other body that processes personal data on behalf of the person responsible.<br>
        <br>
        <b>&nbsp;• i) Recipient</b><br>
        The recipient is a natural or legal person, public authority, agency or other body to whom personal data are disclosed, regardless of whether it is a third party or not. However, authorities that may receive personal data as part of a specific investigation mandate under Union law or the law of the Member States are not considered recipients.<br>
        <br>
        <b>&nbsp;• j) third party</b><br>
        A third party is a natural or legal person, public authority, agency or other body other than the data subject, the controller, the processor and those authorized to process the personal data under the direct responsibility of the controller or processor.<br>
        <br>
        <b>&nbsp;• k) Consent</b><br>
        Consent is any expression of will voluntarily given by the data subject for the specific case in an informed manner and unequivocally in the form of a declaration or other clear confirmatory act, with which the data subject indicates that they consent to the processing of their personal data is.<br>
        <br>
        <br>
        2. <b>Name and address of the controller</b>
        <br>
        Responsible within the meaning of the General Data Protection Regulation, other data protection laws applicable in the member states of the European Union and other provisions with data protection character is:<br>
        <br>
        COMP.Net GmbH<br>
        <br>
        Grünbergerstraße 140, Gebäude 606<br>
        <br>
        35394 Gießen<br>
        <br>
        Germany<br>
        <br>
        Tel.: +49 641 932210<br>
        <br>
        E-Mail: info@compnetgmbh.de<br>
        <br>
        Website: www.compnetgmbh.de<br>
        <br>
        <br>
        3. <b>Collection of general data and information</b>
        <br>
        The COMP.Net GmbH website records a series of general data and information each time the website is accessed by a data subject or an automated system. This general data and information is stored in the server’s log files. The following can be recorded: (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system accesses our website (so-called referrer), (4) the sub-websites that use an accessing system on our website can be controlled, (5) the date and time of access to the website, (6) an internet protocol address (IP address), (7) the internet service provider of the accessing system and (8) other similar data and information that serves to avert risks in the event of attacks on our information technology systems.<br>
        <br>
        When using this general data and information, COMP.Net GmbH does not draw any conclusions about the person concerned. Rather, this information is required to (1) deliver the content of our website correctly, (2) optimize the content of our website and the advertising for it, (3) to ensure the long-term functionality of our information technology systems and the technology of our website and ( 4) to provide law enforcement authorities with the information necessary for law enforcement in the event of a cyber attack. This anonymously collected data and information is therefore statistically evaluated by COMP.Net GmbH on the one hand and also with the aim of increasing data protection and data security in our company in order to ultimately ensure an optimal level of protection for the personal data we process. The anonymous data of the server log files are stored separately from all personal data provided by a data subject.<br>
        <br>
        <br>
        4. <b>Registration on our website</b><br>
        <br>
        The data subject has the option of registering on the website of the data controller by providing personal data. Which personal data are transmitted to the person responsible for processing results from the respective input mask used for registration. The personal data entered by the data subject are collected and stored exclusively for internal use by the controller and for their own purposes. The controller can arrange for the transfer to one or more processors, for example a parcel service provider, who also uses the personal data exclusively for internal use, which is attributable to the controller.<br>
        <br>
        By registering on the website of the controller, the IP address assigned by the data subject’s Internet service provider (ISP), the date and the time of registration are also saved. This data is stored against the background that this is the only way to prevent the misuse of our services, and if necessary, this data enables criminal offenses committed to be investigated. In this respect, the storage of this data is necessary to secure the data controller. As a matter of principle, this data will not be passed on to third parties unless there is a legal obligation to pass it on or the passing on is used for criminal prosecution.<br>
        <br>
        The registration of the data subject with the voluntary provision of personal data serves the data controller to offer the data subject content or services that, due to the nature of the matter, can only be offered to registered users. Registered persons are free to change the personal data provided during registration at any time or to have them completely deleted from the data base of the controller.<br>
        <br>
        The data controller will provide each data subject with information on what personal data about the data subject are stored at any time on request. Furthermore, the person responsible for the processing corrects or deletes the personal data at the request or advice of the data subject, provided that there are no statutory retention requirements. A data protection officer named by name in this data protection declaration and all employees of the person responsible for processing are available to the data subject as contact persons in this context.<br>
        <br>
        <br>
        5. <b>Routine deletion and blocking of personal data</b><br>
        <br>
        The data controller processes and stores personal data of the data subject only for the period necessary to achieve the storage purpose or if this is done by the European directive and regulation giver or another legislator in laws or regulations, which of the data controller is subject to, was provided.<br>
        <br>
        If the storage purpose no longer applies or if a storage period prescribed by the European directive and ordinance provider or another responsible legislator expires, the personal data will be routinely blocked or deleted in accordance with the statutory provisions.<br>
        <br>
        <br>
        6. <b>Rights of the data subject</b><br>
        <br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;• a) Rights of the data subject</b><br>
        Every data subject has the right granted by the European directive and regulation giver to ask the data controller for confirmation as to whether personal data concerning them are being processed. If a data subject wishes to exercise this right of confirmation, they can contact our data protection officer or another employee of the controller at any time.<br>
        <br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;• b) Right to information</b><br>
        Any person affected by the processing of personal data has the right granted by the European directive and regulation giver to receive free of charge information about the personal data stored about him and a copy of this information from the controller at any time. Furthermore, the European directive and regulation giver has granted the data subject information about the following information:<br>
        <br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>the processing purposes<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>the categories of personal data that are processed<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>the recipients or categories of recipients to whom the personal data has been or will be disclosed, in particular for recipients in third countries or with international organizations<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>if possible, the planned duration for which the personal data will be stored or, if this is not possible, the criteria for determining this duration<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>the existence of a right to correction or deletion of the personal data concerning them or to restriction of processing by the person responsible or a right to object to this processing<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>the right to lodge a complaint with a supervisory authority<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>if the personal data is not collected from the data subject: all available information about the origin of the data<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>the existence of automated decision-making, including profiling, in accordance with Article 22 Paragraph 1 and 4 GDPR and – at least in these cases – meaningful information about<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>the logic involved as well as the scope and intended effects of such processing for the data subject<br>
        The data subject also has the right to information as to whether personal data has been transferred to a third country or to an international organization. If this is the case, the data subject also has the right to receive information about the appropriate guarantees in connection with the transfer.<br>
        <br>
        If a data subject wishes to exercise this right to information, they can contact our data protection officer or another employee of the controller at any time.<br>
        <br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;• c) Right to rectification</b><br>
        Any person affected by the processing of personal data has the right granted by the European directive and regulation giver to request the immediate correction of incorrect personal data concerning them. Furthermore, the data subject has the right, taking into account the purposes of the processing, to request the completion of incomplete personal data – also by means of a supplementary statement.<br>
        <br>
        If a data subject wishes to exercise this right of rectification, they can contact our data protection officer or another employee of the controller at any time.<br>
        <br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;• d) Right to erasure (right to be forgotten)</b><br>
        Every person affected by the processing of personal data has the right granted by the European directive and regulation giver to demand from the responsible person that the personal data concerning them be deleted immediately if one of the following reasons applies and as far as the processing is not necessary:<br>
        <br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>The personal data were collected for such purposes or processed in any other way for which they are no longer necessary.<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>The data subject withdraws their consent on which the processing was based in accordance with Art. 6 para. 1 letter a GDPR or Art. 9 para. 2 letter a GDPR, and there is no other legal basis for the processing.<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>In accordance with Art. 21 para. 1 GDPR and there is no overriding legitimate reason for the processing, or the data subject objects to the processing pursuant to Art. 21 para. 2 GDPR to object to processing.<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>The personal data was processed illegally.<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>The deletion of personal data is necessary to fulfill a legal obligation under Union law or the law of the member states to which the controller is subject.<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>The personal data were collected in relation to information society services offered in accordance with Art. 8 para. 1 GDPR.<br>
        If one of the above reasons applies and a data subject wishes to have personal data stored at COMP.Net GmbH deleted, they can contact our data protection officer or another employee of the controller at any time. The data protection officer of COMP.Net GmbH or another employee will arrange for the request for deletion to be complied with immediately.<br>
        <br>
        If the personal data were made public by COMP.Net GmbH and our company is responsible as per Art. 17 para. 1 GDPR obliged to delete the personal data, the COMP.Net GmbH takes appropriate measures, also of a technical nature, taking into account the available technology and the implementation costs, in order to inform other data controllers who process the published personal data to ensure that the data subject has requested that these other data controllers delete all links to this personal data or copies or replications of this personal data, unless the processing is necessary. The data protection officer of COMP.Net GmbH or another employee will arrange the necessary in individual cases.<br>
        <br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;• e) Right to restriction of processing</b><br>
        Any person affected by the processing of personal data has the right granted by the European directive and regulation giver to request the controller to restrict processing if one of the following conditions is met:<br>
        <br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>The data subject disputes the accuracy of the personal data for a period of time that enables the person responsible to check the accuracy of the personal data.<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>The processing is unlawful, the data subject refuses to delete the personal data and instead requests that the use of the personal data be restricted.<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>The controller no longer needs the personal data for the purposes of processing, but the data subject needs them to assert, exercise or defend legal claims.<br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;</b>The data subject has objected to processing in accordance with Art. 21 para. 1 GDPR and it is not yet certain whether the legitimate reasons of the person responsible outweigh those of the data subject.<br>
        If one of the above requirements is met and a data subject wishes to request the restriction of personal data stored at COMP.Net GmbH, they can contact our data protection officer or another employee of the controller at any time. The data protection officer of COMP.Net GmbH or another employee will arrange for the processing to be restricted.<br>
        <br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;• f) Right to data portability</b><br>
        Every person affected by the processing of personal data has the right granted by the European directive and regulation giver to receive the personal data concerning them, which were provided to a responsible person by the data subject, in a structured, common and machine-readable format. You also have the right to transfer this data to another controller without hindrance from the controller to whom the personal data has been provided, provided that the processing is based on consent in accordance with Art. 6 para. 1 letter a GDPR or Art. 9 para. 2 letter a GDPR or on a contract in accordance with Art. 6 para. 1 letter b GDPR and the processing is carried out using automated procedures, unless the processing is necessary for the performance of a task that is in the public interest or in the exercise of official authority that has been transferred to the person responsible.<br>
        <br>
        Furthermore, when exercising their right to data portability in accordance with Art. 20 para. 1 DS-GVO the right to have the personal data transferred directly from one responsible person to another responsible person, insofar as this is technically feasible and provided that this does not affect the rights and freedoms of other people.<br>
        <br>
        In order to assert the right to data portability, the data subject can contact the data protection officer appointed by COMP.Net GmbH or another employee at any time.<br>
        <br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;• g) Right to object</b><br>
        Any person affected by the processing of personal data has the right granted by the European directive and regulation giver, for reasons arising from their particular situation, at any time against the processing of personal data concerning them, which is based on Art. 6 para. 1 letter e or f DS-GVO takes place to object. This also applies to profiling based on these provisions.<br>
        <br>
        In the event of an objection, COMP.Net GmbH no longer processes the personal data, unless we can demonstrate compelling legitimate grounds for the processing that outweigh the interests, rights and freedoms of the data subject, or the processing serves to assert, exercise or defense of legal claims.<br>
        <br>
        If COMP.Net GmbH processes personal data in order to operate direct mail, the data subject has the right to object at any time to the processing of personal data for the purpose of such advertising. This also applies to profiling insofar as it is connected to such direct advertising. If the data subject objects to COMP.Net GmbH for processing for direct marketing purposes, COMP.Net GmbH will no longer process the personal data for these purposes.<br>
        <br>
        In addition, the person concerned has the right, for reasons that arise from their particular situation, against the processing of personal data concerning them, which is carried out at COMP.Net GmbH for scientific or historical research purposes or for statistical purposes in accordance with Art. 89 para. 1 GDPR, to object, unless such processing is necessary to fulfill a task in the public interest.<br>
        <br>
        To exercise the right to object, the data subject can contact the data protection officer of COMP.Net GmbH or another employee directly. The data subject is also free to exercise their right to object in connection with the use of information society services, regardless of Directive 2002/58 / EC, by means of automated procedures that use technical specifications.<br>
        <br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;• h) Automated decisions in individual cases including profiling</b><br>
        Any person affected by the processing of personal data has the right granted by the European directive and regulatory authority not to be subjected to a decision based solely on automated processing – including profiling – which has a legal effect on them or similarly significantly affects them, if the decision (1) is not necessary for the conclusion or performance of a contract between the data subject and the controller, or (2) is permissible due to Union or Member State law to which the controller is subject and this legislation takes appropriate measures to protect the rights and freedoms as well as the legitimate interests of the data subject or (3) with the express consent of the data subject.<br>
        <br>
        If the decision (1) is necessary for the conclusion or performance of a contract between the data subject and the person responsible or (2) it is made with the express consent of the data subject, COMP.Net GmbH takes appropriate measures to protect the rights and freedoms as well to protect the legitimate interests of the data subject, which includes at least the right to obtain the intervention of a person on the part of the person responsible, to state their own position and to contest the decision.<br>
        <br>
        If the data subject wishes to assert rights in relation to automated decisions, they can contact our data protection officer or another employee of the controller at any time.<br>
        <br>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;• i) Right to withdraw consent under data protection law</b><br>
        Every person affected by the processing of personal data has the right granted by the European directive and regulation giver to revoke their consent to the processing of personal data at any time.<br>
        <br>
        If the data subject wishes to exercise their right to withdraw consent, they can contact our data protection officer or another employee of the controller at any time.<br>
        <br>
        7. <b>Data protection for applications and in the application process</b><br>
        The controller collects and processes the personal data of applicants for the purpose of processing the application process. Processing can also be done electronically. This is particularly the case if an applicant sends the relevant application documents to the person responsible for processing by electronic means, for example by email or via a web form on the website. If the controller concludes an employment contract with an applicant, the data transmitted will be stored for the purpose of processing the employment relationship in compliance with the statutory provisions. If the data controller does not conclude an employment contract with the applicant, the application documents will be deleted automatically two months after the announcement of the rejection decision, provided that there are no other legitimate interests of the data controller contrary to deletion. Other legitimate interest in this sense is, for example, an obligation to provide evidence in a procedure under the General Equal Treatment Act (AGG).<br>
        <br>
        <br>
        8. <b>Legal basis for processing</b><br>
        Art. 6 I lit. a GDPR serves our company as the legal basis for processing operations where we obtain consent for a specific processing purpose. If the processing of personal data is necessary for the performance of a contract to which the data subject is a party, as is the case, for example, with processing operations that are necessary for the delivery of goods or the provision of any other service or consideration, the processing is based on Art. 6 I lit. b GDPR. The same applies to such processing operations that are necessary to carry out pre-contractual measures, for example in cases of inquiries about our products or services. If our company is subject to a legal obligation that requires the processing of personal data, such as to fulfill tax obligations, the processing is based on Art. 6 I lit. c GDPR. In rare cases, the processing of personal data may be necessary to protect the vital interests of the data subject or another natural person. This would be the case, for example, if a visitor to our company were injured and his name, age, health insurance data or other vital information had to be passed on to a doctor, hospital or other third party. Then the processing would be based on Art. 6 I lit. d GDPR. Ultimately, processing operations could be based on Art. 6 I lit. f GDPR. Processing operations that are not covered by any of the aforementioned legal bases are based on this legal basis if processing is necessary to safeguard the legitimate interests of our company or a third party, provided that the interests, fundamental rights and freedoms of the data subject do not outweigh them. Such processing operations are permitted to us in particular because they have been specifically mentioned by the European legislator. In this respect, he took the view that a legitimate interest could be assumed if the data subject is a customer of the person responsible (recital 47 sentence 2 GDPR).<br>
        <br>
        <br>
        9. <b>Legitimate interests in the processing that are being pursued by the controller or a third party</b><br>
        Is the processing of personal data based on Article 6 I lit. f GDPR is our legitimate interest in carrying out our business for the benefit of all our employees and our shareholders.<br>
        <br>
        <br>
        10. <b>Duration for which the personal data is stored</b><br>
        The criterion for the duration of the storage of personal data is the respective legal retention period. After the period has expired, the corresponding data will be routinely deleted, provided that it is no longer required to fulfill or initiate a contract.<br>
        <br>
        <br>
        11. <b>Legal or contractual regulations for the provision of personal data; Necessity for the conclusion of the contract; Obligation of the data subject to provide the personal data; possible consequences of not providing</b><br>
        We will inform you that the provision of personal data is partly required by law (e.g. tax regulations) or may also result from contractual regulations (e.g. information about the contract partner). Sometimes it may be necessary to conclude a contract that a data subject provides us with personal data that we subsequently have to process. For example, the person concerned is obliged to provide us with personal data if our company concludes a contract with them. Failure to provide personal data would result in the contract not being concluded with the person concerned. Before the data subject provides personal data, the data subject must contact our data protection officer. Our data protection officer clarifies the data subject on a case-by-case basis whether the provision of personal data is required by law or contract or is required for the conclusion of the contract, whether there is an obligation to provide the personal data and the consequences of not providing the personal data.<br>
        <br>
        <br>
        12. <b>Existing automated decision making</b><br>
        As a responsible company, we do not use automatic decision-making or profiling.<br>
        <br>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dataProtectionDialog",
  props: ["value"],
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
</style>