const jwt = require("jsonwebtoken");

// TODO: Key from API
var publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDSqvIuw2I2TW/NNRfZ3l1wIPy6
/4g49Fvdsds0XL7c9mGY5aKNalRtVAWqhYRaQ3OzGPNIoDE9HguCHFqFYSugMixE
zL96O2JgJ7fgmZjdOxhde0T5kn84uxfD1MWs2oJcrgZa4bMaOSd7Gf25xZ00X+ZX
mtS+CSqYQzlK60EGgwIDAQAB
-----END PUBLIC KEY-----`;

module.exports = {
  verify: (token) => {
    if (!token) return false;
    var verifyOptions = {
      issuer: "COMP.net GmbH",
      audience: "COBI License Portal User",
      expiresIn: "12h",
      algorithms: ["RS256"],
    };
    try {
      return jwt.verify(token, publicKey, verifyOptions);
    } catch (err) {
      return false;
    }
  },
  decode: (token) => {
    if (!token) return null;
    return jwt.decode(token, { complete: true });
    //  returns null if token is invalid
  },
};
