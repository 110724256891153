import axios from "axios";
import Vue from "vue";

const state = {
  customerlicensesFetched: false,
  lastFetch: 0,
  newCustomerlicense: {
    customerid: null,
    subcustomerid: null,
    templateid: null,
    amount: null,
    subscription: false,
    licenseid: null,
    purchaseid: "",
    months: null,
    issued: "",
    expires: "",
    finalprice: "",
    pending: false,
    blocked: false,
    grantedby: null,
    nfr: false,
    requestedat: "",
  },
  customerlicenses: [
    // {
    //   customerid: 1,
    //   subcustomerid: 1,
    //   templateid: 1,
    //   amount: 5,
    //   subscription: false,
    //   licenseid: 1,
    //   purchaseid: "",
    //   months: 0,
    //   issued: "",
    //   expires: "",
    //   finalprice: "1125.00",
    //   pending: false,
    //   blocked: false,
    //   grantedby: 1,
    //   nfr: false,
    //   requestedat: "",
    // },
    // {
    //   customerid: 1,
    //   subcustomerid: 1,
    //   templateid: 2,
    //   amount: 10,
    //   subscription: false,
    //   licenseid: 2,
    //   purchaseid: "",
    //   months: 0,
    //   issued: "",
    //   expires: "",
    //   finalprice: "1000.00",
    //   pending: false,
    //   blocked: false,
    //   grantedby: 1,
    //   nfr: false,
    //   requestedat: "",
    // },
    // {
    //   customerid: 1,
    //   subcustomerid: 2,
    //   templateid: 3,
    //   amount: 50,
    //   subscription: true,
    //   licenseid: 3,
    //   purchaseid: "",
    //   months: 24,
    //   issued: "",
    //   expires: "",
    //   finalprice: "1500.00",
    //   pending: false,
    //   blocked: false,
    //   grantedby: 1,
    //   nfr: false,
    //   requestedat: "",
    // },
    // {
    //   customerid: 2,
    //   subcustomerid: 3,
    //   templateid: 4,
    //   amount: 15,
    //   subscription: true,
    //   licenseid: 4,
    //   purchaseid: "",
    //   months: 24,
    //   issued: "",
    //   expires: "",
    //   finalprice: "300.00",
    //   pending: false,
    //   blocked: false,
    //   grantedby: 1,
    //   nfr: false,
    //   requestedat: "",
    // },
    // {
    //   customerid: 2,
    //   subcustomerid: 3,
    //   templateid: 5,
    //   amount: 10,
    //   subscription: false,
    //   licenseid: 5,
    //   purchaseid: "",
    //   months: 0,
    //   issued: "",
    //   expires: "",
    //   finalprice: "5000.00",
    //   pending: false,
    //   blocked: false,
    //   grantedby: 1,
    //   nfr: false,
    //   requestedat: "",
    // },
  ],
};

const getters = {
  allCustomerlicenses: (state) => state.customerlicenses,
  customerlicensesByLicenseId: (state) => (licenseid) => {
    return state.customerlicenses.find((x) => x.licenseid == licenseid);
  },
  customerlicensesByCustomerId: (state) => (customerid) => {
    return state.customerlicenses.filter((x) => x.customerid == customerid);
  },
  customerlicensesBySubcustomerId: (state) => (subcustomerid) => {
    return state.customerlicenses.filter(
      (x) => x.subcustomerid == subcustomerid
    );
  },
  customerlicensesBySubcustomerIdActiveLicenses: (state) => (subcustomerid) => {
    return state.customerlicenses.filter(
      (x) => x.subcustomerid == subcustomerid && x.issued
    );
  },
  customerlicensesBySubcustomerIdPendingLicenses: (state) => (subcustomerid) => {
    return state.customerlicenses.filter(
      (x) => x.subcustomerid == subcustomerid && !x.issued
    );
  },
  customerlicenseByPurchaseId: (state) => (purchaseid) => {
    return state.customerlicenses.find((x) => x.purchaseid == purchaseid);
  },
  customerlicensesByPurchaseId: (state) => (purchaseid) => {
    return state.customerlicenses.filter((x) => x.purchaseid == purchaseid);
  },
  pendingCustomerlicenses: (state) => {
    return state.customerlicenses.filter((x) => x.pending === true);
  },
  activeUniqueCustomerlicenses: (state) => {
    var activeLicenses = state.customerlicenses.filter(
      (x) => x.pending == false && x.blocked == false
    );
    var unique = [];
    activeLicenses.forEach((a) => {
      if (!unique.find((u) => u.purchaseid == a.purchaseid)) {
        unique.push(a);
      }
    });
    return unique;
  },
  activeUniqueCustomerlicensesByMonth: (state) => (date) => {
    var firstMs = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
    var lastMs = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0,
      23,
      59,
      59,
      999
    );
    var activeLicenses = state.customerlicenses.filter(
      (x) =>
        !x.pending &&
        !x.blocked &&
        Number(x.expires) >= firstMs.getTime() &&
        Number(x.expires) <= lastMs.getTime()
    );
    var unique = [];
    activeLicenses.forEach((a) => {
      if (!unique.find((u) => u.purchaseid == a.purchaseid)) {
        unique.push(a);
      }
    });
    return unique;
  },
  activeUniqueCustomerlicensesByCustomerId: (state) => (customerid) => {
    var activeLicensesByCustomerId = state.customerlicenses.filter((x) => {
      if (x.pending || x.blocked || x.customerid != customerid) {
        return false;
      } else if (Date(x.expires) > Date(Date.now())) {
        return false;
      } else {
        return true;
      }
    });
    var unique = [];
    activeLicensesByCustomerId.forEach((a) => {
      if (!unique.find((u) => u.purchaseid == a.purchaseid)) {
        unique.push(a);
      }
    });
    return unique;
  },
  activeUniqueCustomerlicensesBySubcustomerId: (state) => (subcustomerid) => {
    var activeLicensesBySubcustomerId = state.customerlicenses.filter((x) => {
      if (x.pending || x.blocked || x.subcustomerid != subcustomerid) {
        return false;
      } else if (Date(x.expires) > Date(Date.now())) {
        return false;
      } else {
        return true;
      }
    });
    var unique = [];
    activeLicensesBySubcustomerId.forEach((a) => {
      if (!unique.find((u) => u.purchaseid == a.purchaseid)) {
        unique.push(a);
      }
    });
    return unique;
  },
  pendingUniqueCustomerlicenses: (state) => {
    var pending = state.customerlicenses.filter((x) => x.pending === true);
    var unique = [];
    pending.forEach((p) => {
      if (!unique.find((u) => u.purchaseid == p.purchaseid)) {
        unique.push(p);
      }
    });
    return unique;
  },
  groupedCustomerlicenses: (state) => (sortFunction) => {
    var unique = [];
    state.customerlicenses.forEach((customerlicense) => {
      var existingUnique = unique.find(
        (u) => u.purchaseid == customerlicense.purchaseid
      );
      if (existingUnique) {
        if (!Array.isArray(existingUnique.licenseid)) {
          var licenseid = existingUnique.licenseid;
          existingUnique.licenseid = new Array();
          existingUnique.licenseid.push(licenseid);
        }
        if (!existingUnique.licenseid.includes(customerlicense.licenseid)) {
          existingUnique.licenseid.push(customerlicense.licenseid);
        }
      } else {
        unique.push(customerlicense);
      }
    });
    unique.sort((a, b) => {
      return (
        Number(a.purchaseid.split("_")[0]) - Number(b.purchaseid.split("_")[0])
      );
    });
    if (sortFunction) {
      unique.sort(sortFunction);
    }
    return unique;
  },
};

const actions = {
  fetchCustomerlicenses({ commit }) {
    Vue.prototype.$axios.get("/customerlicenses").then((response) => {
      commit("SET_CUSTOMERLICENSES", response.data);
    });
  },
  fetchCustomerlicensesAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/customerlicenses")
        .then((response) => {
          commit("SET_CUSTOMERLICENSES", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postCustomerlicenseForAdmin({ commit }, license) {
    return Vue.prototype.$axios
      .post("/customerlicenses/admin", license)
      .then((response) => {
        license.finalprice = response.data[0].finalprice
        commit("ADD_CUSTOMERLICENSE", license)
      })
      .catch((error) => {
        console.log(error);
        return `Somthing went wrong. ${error}`
      });
  },

  updateCustomerlicense({ commit }, license) {
    console.log("Updating license {0}".format(license.licenseid));

    Vue.prototype.$axios
      .patch("/customerlicenses", license)
      .then((response) => {
        commit("UPDATE_CUSTOMERLICENSE", license);
      })
      .catch((error) => { });
  },
  async deleteCustomerlicense({ commit }, license) {
    console.log("Start");
    try {
      var response = await Vue.prototype.$axios.delete("/customerlicenses", {
        data: license,
      });
      console.log(license);
      commit("DELETE_CUSTOMERLICENSE", license);
    } catch (exception) {
      console.log(exception);
      return exception.response.data[0].message;
    }
  },
  getSortedCustomerlicenses({ commit, getters }, sortFunction) {
    var sorted = getters.groupedCustomerlicenses.sort(sortFunction);
    return sorted;
  },
  getActivationKey({ commit }, licenseid) {
    return Vue.prototype.$axios
      .get("/customerlicenses/export", { params: { licenseid: licenseid } })
      .then((response) => {
        if (response.data.trim() != "") {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "activationkey.txt");
          document.body.appendChild(fileLink);
          fileLink.click();
        } else {
          return "Error";
        }
      })
      .catch((exception) => exception.response.data[0].message);
  }

};

const mutations = {
  ADD_CUSTOMERLICENSE(state, license) {
    state.customerlicenses.push(license)
  },
  SET_CUSTOMERLICENSES(state, customerlicenses) {
    state.customerlicenses = customerlicenses;
    state.customerlicensesFetched = true;
  },
  UPDATE_CUSTOMERLICENSE(state, updateLicense) {
    var license = state.customerlicenses.find(
      (x) => x.licenseid == updateLicense.licenseid
    );
    license.blocked = updateLicense.blocked;
    license.issued = updateLicense.issued;
    license.expires = updateLicense.expires;
    license.finalprice = updateLicense.finalprice;
  },
  DELETE_CUSTOMERLICENSE(state, license) {
    var idx = state.customerlicenses.findIndex(
      (x) => x.licenseid == license.licenseid
    );
    state.customerlicenses.splice(idx, 1);
    console.log(state.customerlicenses);
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
