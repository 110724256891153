import axios from "axios";
import Vue from "vue";

const state = {
    topics: []
};

const getters = {
    getTopics: (state) => state.topics,
    getTopicById: (state) => (id) => state.topics.filter(x => x.id == id)[0],
};

const actions = {
    fetchTopics({ commit }) {
        Vue.prototype.$axios.get("/topics")
            .then(res => {
                commit("SET_TOPIC", res.data)
            })
            .catch((exception) => exception.response.data[0].message)
    },
    postTopic({ commit }, topictype) {
        Vue.prototype.$axios.post("/topics", topictype)
            .then(res => {
                //res topic + id schreiben
                console.log("topic");
                console.log(res);
                commit("ADD_TOPIC", res.data[0])
            })
            .catch((exception) => exception.response.data[0].message)
    },
    deleteTopic({ commit }, topic) {
        Vue.prototype.$axios.delete("/topics", { data: { "id": topic.id } })
            .then(res => {
                commit("REMOVE_TOPIC", topic)
            })
            .catch((exception) => {
                console.log(exception.response.data);
                return exception.response.data})
    }
};

const mutations = {
    SET_TOPIC(state, topics) {
        state.topics = topics
    },
    ADD_TOPIC(state, topic) {
        state.topics.push(topic)
    },
    REMOVE_TOPIC(state, topic) {
        console.log(state.topics);
        const index = state.topics.indexOf(topic)
        state.topics.splice(index, 1)
    }
};

export default {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
