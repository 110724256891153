//import { apply } from 'core-js/fn/reflect'
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Login from "../views/auth/Login";
import jwt from "../helpers/jwt";
import { JsonWebTokenError } from "jsonwebtoken";

Vue.use(VueRouter);

const checkLoginStatus = function () {
  let token = localStorage.getItem("jwt");
  if (token) {
    if (jwt.verify(token)) {
      let user = jwt.decode(token);
      if (user.payload.is_admin || user.payload.is_manager) return "/admin";
    }
  }
  return "/login";
};

const routes = [
  {
    path: "/",
    redirect: checkLoginStatus(),
    meta: {
      auth: true,
      minPermissionLevel: 1,
    },
  },

  //name:   Debug
  //route:  /admin/debug

  //name: Playground
  //route: /playground/
  {
    path: "/playground",
    name: "Playground",
    component: () => import("../views/playground/Playground"),
    redirect: "/playground/testing1",
    meta: {
      auth: true,
      minPermissionLevel: 3,
    },
    children: [
      //name: Testing1
      //route: /playground/testing1
      {
        path: "testing1",
        name: "Testing1",
        component: () => import("../views/playground/Testing1/Testing1"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },

      //name: Testing2
      //route: /playground/testing2
      {
        path: "testing2",
        name: "Testing2",
        component: () => import("../views/playground/Testing2"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },

      //name: GqlTest
      //route: /playground/gqltest
      {
        path: "gqltest",
        name: "GqlTest",
        component: () => import("../views/playground/GqlTest"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },
    ],
  },

  //name:   Admin
  //route:  /admin/
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/admin/Admin"),
    redirect: "/admin/partners",
    meta: {
      auth: true,
      minPermissionLevel: 3,
    },
    children: [
      // name:    Messages
      // route:   /admin/messages
      /* {
        path: "messages",
        name: "Messages",
        component: () => import("../views/admin/Messages"),
        redirect: "/admin/messages/messages",
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
        children: [
          // name:    Issues Tab
          // route:   /admin/messages/issues
          // {
          //   path: "issues",
          //   name: "Issues Tab",
          //   component: () =>
          //     import("../components/admin/messages/tabs/issues/issuestab"),
          //   meta: {
          //     auth: true,
          //     minPermissionLevel: 3,
          //   },
          // }, 
          // name:    Messages Tab
          // route:   /admin/messages/messages
          {
            path: "messages",
            name: "Messages Tab",
            component: () =>
              import("../components/admin/messages/tabs/messages/messagestab"),
            meta: {
              auth: true,
              minPermissionLevel: 3,
            },
          },
          // name:    Notificationlists Tab
          // route:   /admin/messages/notificationlists
          {
            path: "notificationlists",
            name: "Notificationlists Tab",
            component: () =>
              import(
                "../components/admin/messages/tabs/notificationlists/notificationliststab"
              ),
            meta: {
              auth: true,
              minPermissionLevel: 3,
            },
          },
          // name:    Chat view
          // route:   /admin/messages/chatid
          {
            path: ":id",
            name: "Chat View",
            component: () => import("../components/admin/messages/tabs/chat"),
            meta: {
              auth: true,
              minPermissionLevel: 3,
            },
          },
        ],
      }, */
      // name:   Announcement
      // route:  /admin/announcements
      {
        path: "announcements",
        name: "Announcement",
        redirect: "announcements/write",
        component: () => import("../views/admin/AnnouncementEditor"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
        children: [
          // name:    Announcement view Tab
          // route:   /admin/announcements/view
          {
            path: "view",
            name: "View Announcement Tab",
            component: () =>
              import(
                "../components/admin/announcements/view/announcementstab"
              ),
            meta: {
              auth: true,
              minPermissionLevel: 3,
            },
          },
          // name:    Announcement write Tab
          // route:   /admin/announcements/view
          {
            path: "write",
            name: "Write Announcement Tab",
            component: () =>
              import(
                "../components/admin/announcements/editor/writeannouncementtab"
              ),
            meta: {
              auth: true,
              minPermissionLevel: 3,
            },
          },
        ]
      },
      // name:   History
      // route:  /admin/history
      {
        path: "history",
        name: "History",
        component: () => import("../views/admin/History"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },
      // name:   Requests
      // route:  /admin/requests
      {
        path: "requests",
        name: "Requests",
        component: () => import("../views/admin/Requests"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },

      // name:   Partners
      // route:  /admin/partners
      {
        path: "partners",
        name: "Partners",
        component: () => import("../views/admin/Partners"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },

      // name:   Users
      // route:  /admin/users
      {
        path: "users",
        name: "Users",
        component: () => import("../views/admin/Users"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },

      // name:   Licensesettings
      // route:  /admin/licensesettings
      {
        path: "licensesettings",
        name: "Licensesettings",
        component: () => import("../views/admin/LicenseSettings"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },

      // name:   Training
      // route:  /admin/training/
      {
        path: "training",
        name: "Training",
        component: () => import("../views/admin/training/Training"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
        children: [
          // name:   Schedule
          // route:  /admin/training/schedule
          {
            path: "schedule",
            name: "Schedule",
            component: () => import("../views/admin/training/Schedule"),
            meta: {
              auth: true,
              minPermissionLevel: 3,
            },
          },

          // name:   Booking
          // route:  /admin/training/booking
          {
            path: "booking",
            name: "Booking",
            component: () => import("../views/admin/training/Booking"),
            meta: {
              auth: true,
              minPermissionLevel: 3,
            },
          },
        ],
      },

      // name:   Resources
      // route:  /admin/resources
      {
        path: "resources",
        name: "Resources",
        component: () => import("../views/admin/resources/Resources"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
        children: [
          // name:   Pricelist
          // route:  /admin/resources/pricelist
          {
            path: "pricelist",
            name: "Pricelist",
            component: () => import("../views/admin/resources/PriceList"),
            meta: {
              auth: true,
              minPermissionLevel: 3,
            },
          },

          // name:   Products
          // route:  /admin/resources/products
          {
            path: "products",
            name: "Products",
            component: () => import("../views/admin/resources/Products"),
            meta: {
              auth: true,
              minPermissionLevel: 3,
            },
          },
        ],
      },

      // name:   Marketingresources
      // route:  /admin/marketingresources
      {
        path: "marketingresources",
        name: "MarketingResources",
        component: () => import("../views/general/Resources_View"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
        children: [
          // name:   Resources
          // path:   /admin/marketingresources/resources
          {
            path: "brochures",
            name: "Brochures",
            component: () => import("../views/general/Resources"),
            meta: {
              auth: true,
              minPermissionLevel: 3,
            },
          },
          // name:   Documentations
          // path:   /admin/marketingresources/documentations
          {
            path: "documentations",
            name: "Documentations",
            component: () => import("../views/general/Resources_Documentation"),
            meta: {
              auth: true,
              minPermissionLevel: 3,
            },
          },
          // name:  Pricelists
          // path:  /admin/marketingresources/pricelists
          {
            path: "pricelists",
            name: "Pricelists",
            component: () => import("../views/general/Resources_Pricelists"),
            meta: {
              auth: true,
              minPermissionLevel: 1,
            },
            children: [
              {
                path: "current",
                name: "Current",
                component: () =>
                  import("../views/general/Resources_Pricelists_current"),
                meta: {
                  auth: true,
                  minPermissionLevel: 1,
                },
              },
              /*               {
                              path: "2024",
                              name: "2024",
                              component: () =>
                                import("../views/general/Resources_Pricelists_2024"),
                              meta: {
                                auth: true,
                                minPermissionLevel: 3,
                              },
                            }, */
            ],
          },
        ],
      },

      // name:   News
      // route:  /admin/news
      {
        path: "news",
        name: "News",
        component: () => import("../views/admin/News"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },

      // name:   Upload
      // route:  /admin/upload
      {
        path: "upload",
        name: "Upload",
        component: () => import("../views/admin/Upload"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },

      // name:   Settings
      // route:  /admin/settings
      {
        path: "settings",
        name: "Settings",
        component: () => import("../views/admin/Settings"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },

      // name:   Usersettings
      // route:  /admin/usersettings
      {
        path: "usersettings",
        name: "Usersettings",
        component: () => import("../views/admin/UserSettings"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },

      // name:   Profile
      // route:  /admin/profile
      {
        path: "profile",
        name: "Profile",
        component: () => import("../views/admin/Profile"),
        meta: {
          auth: true,
          minPermissionLevel: 3,
        },
      },
    ],
  },

  // name:   User
  // route:  /user
  {
    path: "/user",
    name: "User",
    component: () => import("../views/user/User"),
    redirect: "/user/dashboard",
    meta: {
      auth: true,
      minPermissionLevel: 1,
    },
    children: [
      // name:    Issues
      // description: My submitted issues
      // route:   /user/issues
      /* {
        path: "messages/issues",
        name: "Issues",
        component: () => import("../views/user/issues"),
        meta: {
          auth: true,
          minPermissionLevel: 1,
        },
      }, */
      // name:    Messages
      // route:   /user/messages
      /* {
        path: "messages/messages",
        name: "Messages",
        component: () => import("../views/user/Messages"),
        meta: {
          auth: true,
          minPermissionLevel: 1,
        },
      },
      {
        path: "messages/:id",
        name: "Chat View",
        component: () => import("../components/admin/messages/tabs/chat"),
        meta: {
          auth: true,
          minPermissionLevel: 1,
        },
      }, */
      // name:   Issue
      // route:  /user/projects
      {
        path: "issues",
        name: "User Issues",
        component: () => import("../views/user/UserProject"),
        meta: {
          auth: true,
          minPermissionLevel: 1,
        },
      },
      // name:   Dashboard
      // route:  /user/dashboard
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("../views/user/Dashboard"),
        meta: {
          auth: true,
          minPermissionLevel: 1,
        },
      },
      // name:   Announcements
      // route:  /user/announcements
      {
        path: "announcements",
        name: "announcements",
        component: () => import("../views/user/Announcements"),
        meta: {
          auth: true,
          minPermissionLevel: 1,
        },
      },

      // name:   Customers
      // route:  /user/customers
      {
        path: "customers",
        name: "customers",
        component: () => import("../views/user/Customers"),
        meta: {
          auth: true,
          minPermissionLevel: 1,
        },
      },
      // name:   Resources
      // route:  /user/resources
      {
        path: "resources",
        name: "Resources_User",
        component: () => import("../views/admin/resources/Resources"),
        meta: {
          auth: true,
          minPermissionLevel: 1,
        },
        children: [
          // name:   Pricelist
          // route:  /user/pricelist
          {
            path: "pricelist",
            name: "Pricelist_User",
            component: () => import("../views/admin/resources/PriceList"),
            meta: {
              auth: true,
              minPermissionLevel: 1,
            },
          },
          // name:   Products
          // route:  /user/products
          {
            path: "products",
            name: "Products_User",
            component: () => import("../views/admin/resources/Products"),
            meta: {
              auth: true,
              minPermissionLevel: 1,
            },
          },
        ],
      },
      // name:   Settings
      // route:  /user/settings
      {
        path: "settings",
        name: "settings",
        component: () => import("../views/user/Settings"),
        meta: {
          auth: true,
          minPermissionLevel: 1,
        },
      },
      // name:   Profile
      // route:  /user/profile
      {
        path: "profile",
        name: "profile",
        component: () => import("../views/admin/Profile"),
        meta: {
          auth: true,
          minPermissionLevel: 1,
        },
      },
      // name:   Marketingresources
      // route:  /user/marketingresources
      {
        path: "marketingresources",
        name: "MarketingResources",
        component: () => import("../views/general/Resources_View"),
        meta: {
          auth: true,
          minPermissionLevel: 1,
        },
        children: [
          // name:   Resources
          // route:  /user/marketingresources/resources
          {
            path: "brochures",
            name: "Brochures",
            component: () => import("../views/general/Resources"),
            meta: {
              auth: true,
              minPermissionLevel: 1,
            },
          },
          // name:   Documentations
          // route:  /user/marketingresources/documentations
          {
            path: "documentations",
            name: "Documentations",
            component: () => import("../views/general/Resources_Documentation"),
            meta: {
              auth: true,
              minPermissionLevel: 1,
            },
          },
          {
            path: "pricelists",
            name: "Pricelists",
            component: () => import("../views/general/Resources_Pricelists"),
            meta: {
              auth: true,
              minPermissionLevel: 1,
            },
            children: [
              {
                path: "current",
                name: "Current",
                component: () =>
                  import("../views/general/Resources_Pricelists_current"),
                meta: {
                  auth: true,
                  minPermissionLevel: 1,
                },
              },
              /*               {
                              path: "2024",
                              name: "2024",
                              component: () =>
                                import("../views/general/Resources_Pricelists_2024"),
                              meta: {
                                auth: true,
                                minPermissionLevel: 1,
                              },
                            }, */
            ],
          },
        ],
      },
    ],
  },

  // name:   Docs
  // route:  /docs/
  {
    path: "/docs",
    name: "Docs",
    component: () => import("../views/docs/Docs"),
    meta: {
      auth: false,
      minPermissionLevel: 0,
    },
    children: [
      // name:   wms
      // route:  /docs/wms
      {
        path: "wms",
        name: "docs_wms",
        component: () => import("../views/docs/Docs_wms"),
        meta: {
          auth: false,
          minPermissionLevel: 0,
        },
      },

      {
        path: "wms_manual_en",
        component: () => import("../views/docs/Docs_wms_manual_en"),
        meta: {
          auth: false,
          minPermissionLevel: 0,
        },
      },

      {
        path: "wms_manual_de",
        component: () => import("../views/docs/Docs_wms_manual_de"),
        meta: {
          auth: false,
          minPermissionLevel: 0,
        },
      },
      // name:   time
      // route:  /docs/time
      {
        path: "time",
        name: "docs_time",
        component: () => import("../views/docs/Docs_time"),
        meta: {
          auth: false,
          minPermissionLevel: 0,
        },
      },
      // name:   time
      // route:  /docs/time_manual_de
      {
        path: "time_manual_de",
        name: "docs_time_manual_de",
        component: () => import("../views/docs/Docs_time_manual_de"),
        meta: {
          auth: false,
          minPermissionLevel: 0,
        },
      },
      // name:   time
      // route:  /docs/time_manual_en
      {
        path: "time_manual_en",
        name: "docs_time_manual_en",
        component: () => import("../views/docs/Docs_time_manual_en"),
        meta: {
          auth: false,
          minPermissionLevel: 0,
        },
      },
      // name:   time
      // route:  /docs/time_installation_en
      {
        path: "time_installation_en",
        name: "docs_time_installation_en",
        component: () => import("../views/docs/Docs_time_installation_en"),
        meta: {
          auth: false,
          minPermissionLevel: 0,
        },
      },
      // name:   ppc
      // route:  /docs/ppc
      {
        path: "ppc",
        name: "docs_ppc",
        component: () => import("../views/docs/Docs_ppc"),
        meta: {
          auth: false,
          minPermissionLevel: 0,
        },
      },
      // name:   edi
      // route:  /docs/edi
      /* {
        path: "edi",
        name: "docs_edi",
        component: () => import("../views/docs/Docs_edi"),
        meta: {
          auth: false,
          minPermissionLevel: 0,
        },
      }, */
      // name:   msv
      // route:  /docs/msv
      {
        path: "msv",
        name: "docs_msv",
        component: () => import("../views/docs/Docs_msv"),
        meta: {
          auth: false,
          minPermissionLevel: 0,
        },
      },
      // name:   msv
      // route:  /docs/msv_de
      {
        path: "msv_de",
        name: "docs_msv_de",
        component: () => import("../views/docs/Docs_msv_de"),
        meta: {
          auth: false,
          minPermissionLevel: 0,
        },
      },
    ],
  },
  // name:   Login
  // route:  /login
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login"),
    meta: {
      auth: false,
      minPermissionLevel: 0,
    },
    props: {
      isLoggingIn: true,
    },
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: () => import("../views/auth/Reset"),
    meta: {
      auth: false,
      minPermissionLevel: 0,
    },
  },
  // name:   Catchall
  // route:  /*
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("../views/PageNotFound"),
    meta: {
      auth: false,
      minPermissionLevel: 0,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const getPermissionLevel = function (token) {
  const jwt = require("../helpers/jwt");
  if (!token) return 0;
  if (!jwt.verify(token)) return 0;

  var payload = jwt.decode(token).payload;

  if (payload.is_admin) return 4;
  else if (payload.is_manager) return 3;
  else if (payload.is_partner) return 2;
  else if (payload.is_customer) return 1;
  else return 0;
};

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("jwt");
  var perm = getPermissionLevel(token);

  if (to.meta.auth) {
    if (to.meta.minPermissionLevel <= perm) {
      next();
    } else {
      next({ path: "/login", query: { redirect: to.path } });
    }
  } else {
    next();
  }

  /*
  if (to.path != "/login") {
    const jwt = require("../helpers/jwt");
    var token = localStorage.getItem("jwt");
    if (token && jwt.verify(token)) {
      next();
    } else {
      next({ path: "/login", query: { redirect: to.path } });
    }
  } else {
    next();
  }
  */
});

export default router;
