import Vue from "vue";

const state = {
  subcustomersFetched: false,
  lastFetch: 0,
  newSubcustomer: {
    customerid: null,
    name: "",
    email: "",
    contact: "",
    contactforename: "",
    contactmiddlename: "",
    customerof: null,
    maintenance_active: true,
    city: "",
    street: "",
    hnumber: "",
    tel: "",
    notes: "",
    website: "",
    industry: "",
    titel: "",
    postal: "",
    country: "",
    position: "",
  },
  subcustomers: [
    // {
    //   customerid: 1,
    //   name: "Subcustomer 1",
    //   email: "sub@customer1.de",
    //   contact: "Some Name 1",
    //   contactforename: "",
    //   contactmiddlename: "",
    //   customerof: 1,
    //   maintenance_active: true,
    //   city: "Gießen",
    //   street: "Grünberger Straße",
    //   hnumber: "160",
    //   tel: "0123456789",
    //   notes: "Some notes to Subcustomer1",
    //   website: "",
    //   industry: "",
    //   titel: "",
    //   postal: "",
    //   country: "",
    //   position: "",
    // },
    // {
    //   customerid: 2,
    //   name: "Subcustomer 2",
    //   email: "sub@customer2.de",
    //   contact: "Some Name 2",
    //   contactforename: "",
    //   contactmiddlename: "",
    //   customerof: 1,
    //   maintenance_active: true,
    //   city: "Gießen",
    //   street: "Grünberger Straße",
    //   hnumber: "160",
    //   tel: "0123456789",
    //   notes: "Some notes to Subcustomer2",
    //   website: "",
    //   industry: "",
    //   titel: "",
    //   postal: "",
    //   country: "",
    //   position: "",
    // },
    // {
    //   customerid: 3,
    //   name: "Subcustomer 1",
    //   email: "sub@customer1.de",
    //   contact: "Some Name 1",
    //   contactforename: "",
    //   contactmiddlename: "",
    //   customerof: 2,
    //   maintenance_active: true,
    //   city: "Gießen",
    //   street: "Grünberger Straße",
    //   hnumber: "160",
    //   tel: "0123456789",
    //   notes: "Some notes to Subcustomer3",
    //   website: "",
    //   industry: "",
    //   titel: "",
    //   postal: "",
    //   country: "",
    //   position: "",
    // },
  ],
};

const getters = {
  allSubcustomers: (state) => state.subcustomers,
  newSubcustomer: (state) => state.newSubcustomer,
  subcustomersByCustomer: (state) => (customerid) => {
    return state.subcustomers.filter((x) => x.customerof == customerid);
  },
  subcustomerById: (state) => (subcustomerid) => {
    return state.subcustomers.find((x) => x.customerid == subcustomerid);
  },
};

const actions = {
  fetchSubcustomers({ commit }) {
    Vue.prototype.$axios.get("/subcustomers").then((response) => {
      commit("SET_SUBCUSTOMERS", response.data);
    });
  },
  fetchSubcustomersAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/subcustomers")
        .then((response) => {
          commit("SET_SUBCUSTOMERS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async postSubcustomer({ commit }, subcustomer) {
    try {
      var response = await Vue.prototype.$axios.post(
        "/subcustomers",
        subcustomer
      );
      commit("ADD_SUBCUSTOMER", response.data);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
  async patchSubcustomer({ commit }, subcustomer) {
    try {
      console.log(subcustomer);
      var response = await Vue.prototype.$axios.patch(
        "/subcustomers",
        subcustomer
      );
      commit("UPDATE_SUBCUSTOMER", subcustomer);
    } catch (exception) {
      console.log(exception.response.data);
      return exception.response.data[0].message;
    }
  },
  async deleteSubcustomer({ commit }, subcustomer) {
    try {
      var response = await Vue.prototype.$axios.delete("/subcustomers", {
        data: subcustomer,
      });
      commit("DELETE_SUBCUSTOMER", subcustomer);
    } catch (exception) {
      return "Could not be deleted. Are there any licenses assigned to this customer?";
    }
  },
};

const mutations = {
  SET_SUBCUSTOMERS(state, subcustomers) {
    state.subcustomers = subcustomers;
    state.subcustomersFetched = true;
  },
  ADD_SUBCUSTOMER(state, subcustomer) {
    state.subcustomers.push(subcustomer);
  },
  UPDATE_SUBCUSTOMER(state, subcustomer) {
    var idx = state.subcustomers.findIndex(
      (x) => x.customerid == subcustomer.customerid
    );
    Vue.set(state.subcustomers, idx, subcustomer);
  },
  DELETE_SUBCUSTOMER(state, subcustomer) {
    var idx = state.subcustomers.findIndex(
      (x) => x.customerid == subcustomer.customerid
    );
    state.subcustomers.splice(idx, 1);
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
