import axios from "axios";
import Vue from "vue";

const state = {
  templatemodulesFetched: false,
  lastFetch: 0,
  newTemplateModule: {
    id: null,
    moduleid: null,
  },
  templatemodules: [
    // {
    //   id: 1,
    //   moduleid: 1,
    // },
    // {
    //   id: 1,
    //   moduleid: 2,
    // },
    // {
    //   id: 1,
    //   moduleid: 3,
    // },
    // {
    //   id: 2,
    //   moduleid: 1,
    // },
    // {
    //   id: 3,
    //   moduleid: 4,
    // },
    // {
    //   id: 3,
    //   moduleid: 5,
    // },
    // {
    //   id: 3,
    //   moduleid: 6,
    // },
    // {
    //   id: 4,
    //   moduleid: 4,
    // },
    // {
    //   id: 5,
    //   moduleid: 7,
    // },
    // {
    //   id: 6,
    //   moduleid: 8,
    // },
  ],
};

const getters = {
  allTemplateModules: (state) => state.templatemodules,
  newTemplateModule: (state) =>
    JSON.parse(JSON.stringify(state.newTemplateModule)),
  templateModulesByTemplateId: (state) => (templateid) => {
    return state.templatemodules.filter((x) => x.id == templateid);
  },
};

const actions = {
  fetchTemplateModules({ commit }) {
    Vue.prototype.$axios.get("/templatemodules").then((response) => {
      commit("SET_TEMPLATEMODULES", response.data);
    });
  },
  fetchTemplateModulesAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return;
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/templatemodules")
        .then((response) => {
          commit("SET_TEMPLATEMODULES", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async postTemplateModule({ commit }, templatemodule) {
    try {
      var response = await Vue.prototype.$axios.post(
        "/templatemodules",
        templatemodule
      );
      commit("ADD_TEMPLATEMODULE", templatemodule);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
};

const mutations = {
  SET_TEMPLATEMODULES(state, templatemodules) {
    state.templatemodules = templatemodules;
    state.templatemodulesFetched = true;
  },
  ADD_TEMPLATEMODULE(state, templatemodule) {
    state.templatemodules.push(templatemodule);
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
