<template>
  <v-dialog
    v-model="dialog"
    max-width="1500"
  >
    <v-card>
      <v-row>
        <v-col
          cols="12"
          align="right"
        >
          <v-tooltip left>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                right
                top
                fab
                class="mt-2 mr-3"
                outlined
                color="#b31616"
                small
                v-on="on"
                v-bind="attrs"
                @click="dialog=false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-card-text>
        <br>
        <h1>Imprint</h1>
        <br>
        COMP.Net GmbH is a limited liability company under German law based in Giessen.<br>
        <br>
        COMP.Net GmbH<br>
        Grünberger Strasse 140<br>
        Gebäude 606<br>
        35394 Giessen<br>
        <br>
        Tel: +49 641 93 22 1 0<br>
        Fax: +49 641 93 22 1 13<br>
        E-mail: info@compnetgmbh.de<br>
        <br>
        Managing director authorized to represent: Detlef Richard Schreiner<br>
        Register court: Amtsgericht Giessen<br>
        Register number: HR 6297<br>
        Sales tax identification number according to § 27 a sales tax law: DE 24 291 4108<br>
        Responsible for content according to § 6 MDStV: Detlef Schreiner<br>
        <br>
      </v-card-text>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: "imprintDialog",
  props: ["value"],
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
</style>