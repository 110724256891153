import { parse } from "dotenv";
import Vue from "vue";

const state = {
  durationdiscountsFetched: false,
  lastFetch: 0,
  newDurationDiscount: {
    templateid: null,
    duration: 0,
    discount: "0.00",
  },
  durationdiscounts: [
    // {
    //   templateid: 3,
    //   duration: 6,
    //   discount: "10.00",
    // },
    // {
    //   templateid: 3,
    //   duration: 12,
    //   discount: "20.00",
    // },
    // {
    //   templateid: 3,
    //   duration: 24,
    //   discount: "35.00",
    // },
    // {
    //   templateid: 4,
    //   duration: 6,
    //   discount: "5.00",
    // },
    // {
    //   templateid: 4,
    //   duration: 12,
    //   discount: "10.00",
    // },
    // {
    //   templateid: 4,
    //   duration: 24,
    //   discount: "20.00",
    // },
  ],
};

const getters = {
  newDurationDiscount: (state) => state.newDurationDiscount,
  allDurationdiscounts: (state) => state.durationdiscounts,
  durationdiscountsByTemplateId: (state) => (templateid) => {
    return state.durationdiscounts.filter((x) => x.templateid == templateid);
  },
  durationdiscountsByTemplateIdAndDuration:
    (state) => (templateid, duration) => {
      var discounts = state.durationdiscounts.filter(
        (x) => x.templateid == templateid
      );

      if (!discounts) {
        discounts = [
          {
            templateid: templateid,
            duration: 0,
            discount: "0.00",
          },
        ];
      }

      var highest = {
        templateid: templateid,
        duration: 0,
        discount: "0.00",
      };

      discounts.forEach((discount) => {
        if (
          discount.duration <= duration &&
          parseFloat(discount.discount) >= parseFloat(highest.discount)
        ) {
          highest = discount;
        }
      });

      return highest;
    },
};

const actions = {
  fetchDurationdiscounts({ commit }) {
    Vue.prototype.$axios.get("/durationdiscounts").then((response) => {
      commit("SET_DURATIONDISCOUNTS", response.data);
    });
  },
  fetchDurationdiscountsAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return;
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/durationdiscounts")
        .then((response) => {
          commit("SET_DURATIONDISCOUNTS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async postDurationdiscount({ commit }, discount) {
    try {
      var response = await Vue.prototype.$axios.post(
        "/durationdiscounts",
        discount
      );
      commit("ADD_DURATIONDISCOUNT", discount);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
};

const mutations = {
  SET_DURATIONDISCOUNTS(state, durationdiscounts) {
    state.durationdiscounts = durationdiscounts;
    state.durationdiscountsFetched = true;
  },
  ADD_DURATIONDISCOUNT(state, discount) {
    state.durationdiscounts.push(discount);
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
