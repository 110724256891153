import axios from "axios";
import Vue from "vue";

const state = {
    chatMembers: [],
    messaginglistmembersOfGroup: [],
};

const getters = {
    getChatMember: (state) => state.chatMembers,
    getMessaginglistmembersOfGroup: (state) => state.messaginglistmembersOfGroup,
};

const actions = {
    fetchMessaginglistmembersAsync({ commit }) {
        if (state.messaginglistmembersLastFetch + 2000 < Date.now()) {
            state.messaginglistmembersLastFetch = Date.now();
        } else return;
        return new Promise((resolve, reject) => {
            Vue.prototype.$axios
                .get("/messaginglistmembers")
                .then((response) => {
                    commit("SET_MESSAGINGLISTMEMBERS", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchMessaginglistmembersofGroup({ commit }, messaginglistid) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$axios
                .get("/messaginglistmembers", { params: { messaginglistid: messaginglistid } })
                .then((response) => {
                    commit("SET_MESSAGINGLISTMEMBERS_OF_GROUP", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    postMessaginglistmembers({ commit }, chatMembers) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$axios
                .post("/messaginglistmembers", chatMembers)
                .then((response) => {
                    commit("SET_CHATMEMBERS", response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response.data[0].message);
                });
        });
    },
    deleteMessaginglistmembers({ commit }, messaginglistmembers) {
        return new Promise((resolve, reject) => {
            let promises = [];
            messaginglistmembers.forEach((m) => {
                promises.push(
                    new Promise((inner_resolve, inner_reject) => {
                        Vue.prototype.$axios
                            .delete("/messaginglistmembers", { data: m })
                            .then((response) => {
                                commit("DELETE_MESSAGINGLISTMEMBER", m);
                                inner_resolve(response.data);
                            })
                            .catch((error) => {
                                inner_reject(error.response.data[0].message);
                            });
                    })
                );
            });
            Promise.all(promises)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    },

};

const mutations = {
    SET_CHATMEMBERS: (state, chatMembers) => state.chatMembers = chatMembers,
    SET_MESSAGINGLISTMEMBERS(state, messaginglistmembers) {
        state.messaginglistmembers = messaginglistmembers;
    },
    ADD_MESSAGINGLISTMEMBER(state, messaginglistmember) {
        state.messaginglistmembers.push(messaginglistmember);
    },
    DELETE_MESSAGINGLISTMEMBER(state, messaginglistmember) {
        var idx = state.messaginglistmembers.findIndex(
            (x) => x.id == messaginglistmember.id
        );
        state.messaginglistmembers.splice(idx, 1);
    },
    SET_MESSAGINGLISTMEMBERS_OF_GROUP(state, groupMembers) {
        state.messaginglistmembersOfGroup = groupMembers;
    }

};

export default {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
