import axios from "axios";
import Vue from "vue";

const state = {
  messagesFetches: false,
  stepSize: 20,

  messagerecipients: [],
  messages: [],
  messaginglistmembers: [],

  messagerecipientsLastFetch: 0,
  messagesLastFetch: 0,
  messaginglistmembersLastFetch: 0,

  newMessagerecipient: {
    id: null,
    messageid: null,
    messaginglistid: null,
    userid: null,
  },
  newMessage: {
    id: null,
    title: "",
    content: "",
  },
  newMessaginglistmember: {
    id: null,
    messaginglistid: null,
    userid: null,
  },
};

const getters = {
  getStepSize: (state) => state.stepSize,

  allMessagerecipients: (state) => state.messagerecipients,
  allMessages: (state) => state.messages,
  allMessaginglistmembers: (state) => state.messaginglistmembers,

  newMessagerecipient: (state) =>
    JSON.parse(JSON.stringify(state.newMessagerecipient)),
  newMessage: (state) => JSON.parse(JSON.stringify(state.newMessage)),
  newMessaginglistmember: (state) =>
    JSON.parse(JSON.stringify(state.newMessaginglistmember)),

  messaginglistmembersByMessaginglistId: (state) => (messaginglistid) => {
    return state.messaginglistmembers.filter(
      (x) => x.messaginglistid == messaginglistid
    );
  },

  messagerecipientsByMessageId: (state) => (messageid) => {
    return state.messagerecipients.filter((x) => x.messageid == messageid);
  },
};

const actions = {
  fetchMessagerecipientsAsync({ commit }) {
    if (state.messagerecipientsLastFetch + 2000 < Date.now()) {
      state.messagerecipientsLastFetch = Date.now();
    } else return;
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/messagerecipients")
        .then((response) => {
          commit("SET_MESSAGERECIPIENTS", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchMessagesOfGroup({ commit }, obj) {
    /* if (state.messagesLastFetch + 2000 < Date.now()) {
      state.messagesLastFetch = Date.now();
    } else return; */
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/messages", { params: { groupid: obj.groupid, start: obj.start } })
        .then((response) => {
          if (obj.start == 0) {
            commit("SET_MESSAGES", response.data);
          } else {
            commit("APPEND_MESSAGES", response.data);
          }
          resolve(response.data.length);
        })
        .catch((error) => {
          reject(Error(error));
        });
    });
  },
  updateMessage({ commit }, message) {
    return Vue.prototype.$axios
      .patch("/messages", message)
      .then((response) => {
        commit("UPDATE_MESSAGE", message);
      })
      .catch((error) => error.response.data[0].message);
  },
  setMessageAsRead({ commit }, MARId) {
    commit("SET_MESSAGE_READ", MARId);
  },
  deleteMessage({ commit }, mAuId) {
    return Vue.prototype.$axios
      .delete("/messages", { data: { 'id': mAuId.id, 'userid': mAuId.userid } })
      .then((response) => {
        commit("DELETE_MESSAGE", mAuId.id);
      })
      .catch((error) => error.response.data[0].message);
  },
  postMessage({ commit }, message) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .post("/messages", message)
        .then((response) => {
          commit("ADD_MESSAGE", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data[0].message);
        });
    });
  },
  addMessage({ commit }, message) {
    commit("ADD_MESSAGE", message);
  },
  postMessagerecipients({ commit }, messagerecipients) {
    return new Promise((resolve, reject) => {
      let promises = [];
      messagerecipients.forEach((m) => {
        promises.push(
          new Promise((inner_resolve, inner_reject) => {
            Vue.prototype.$axios
              .post("/messagerecipients", m)
              .then((response) => {
                commit("ADD_MESSAGERECIPIENT", response.data);
                inner_resolve(response.data);
              })
              .catch((error) => {
                inner_reject(error.response.data[0].message);
              });
          })
        );
      });
      Promise.all(promises)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  //Messagerecipients
  SET_MESSAGERECIPIENTS(state, messagerecipients) {
    state.messagerecipients = messagerecipients;
  },
  ADD_MESSAGERECIPIENT(state, messagerecipient) {
    state.messagerecipients.push(messagerecipient);
  },

  //Messages
  SET_MESSAGES(state, messages) {
    state.messages = messages;
  },
  APPEND_MESSAGES(state, messages) {
    state.messages.shift(...messages);
  },
  ADD_MESSAGE(state, message) {
    state.messages.push(message);
  },
  UPDATE_MESSAGE(state, message) {
    message.isedited = true;
    state.messages = state.messages.map(m => m.id == message.id ? message : m)
  },
  SET_MESSAGE_READ(state, MARId) {
    state.messages = state.messages.map(x => x.id == MARId.messageId ? { ...x, readerId: MARId.readerId } : x)
  },
  DELETE_MESSAGE(state, messageid) {
    state.messages = state.messages.filter(m => m.id != messageid);
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
