import Vue from "vue";

const state = {
  snack: {
    show: false,
    message: "",
    color: "#b31616",
    timeout: 10000,
  },
};

const getters = {
  getSnackbar: (state) => state.snack,
};

const actions = {
  showSnackbar({ commit }, message) {
    commit("SET_MESSAGE", message);
    commit("SET_SHOW");
  },
  hideSnackbar({commit}){
    commit("SET_SHOW")
  }

};

const mutations = {
  SET_SHOW(state) {
    state.snack.show = !state.snack.show;
  },
  SET_MESSAGE(state, message) {
    state.snack.message = message;
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
