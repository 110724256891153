import Vue from "vue";

const state = {
  contactsFetched: false,
  lastFetch: 0,
  newContact: {
    id: null,
    customerid: null,
    forename: "",
    surname: "",
    email: "",
    phone: "",
    notes: "",
    position: "",
  },
  contacts: [
    // {
    //   id: 1,
    //   customerid: null,
    //   forename: "Sebastian",
    //   surname: "Benk",
    //   email: "sbenk@compnetgmbh.de",
    //   phone: "01234567",
    //   notes: null,
    //   position: "Sales",
    // },
  ],
};

const getters = {
  allContacts: (state) => state.contacts,
  contactsByCustomerId: (state) => (customerid) => {
    return state.contacts.filter((x) => x.customerid == customerid);
  },
  contactById: (state) => (contactid) => {
    return state.contacts.find((x) => x.id == contactid) ?? null;
  },
  newContact: (state) => state.newContact,
};

const actions = {
  fetchContacts({ commit }) {
    Vue.prototype.$axios.get("/contacts").then((response) => {
      commit("SET_CONTACTS", response.data);
    });
  },
  fetchContactsAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return;
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/contacts")
        .then((response) => {
          commit("SET_CONTACTS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async postContact({ commit }, contact) {
    try {
      var response = await Vue.prototype.$axios.post("/contacts", contact);
      commit("ADD_CONTACT", response.data);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
  async patchContact({ commit }, contact) {
    try {
      var response = await Vue.prototype.$axios.patch("/contacts", contact);
      commit("UPDATE_CONTACT", contact);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
  async deleteContact({ commit }, contact) {
    try {
      var response = await Vue.prototype.$axios.delete("/contacts", {
        data: contact,
      });
      commit("DELETE_CONTACT", contact);
    } catch (exception) {
      return exception.response.data;
    }
  },
};

const mutations = {
  SET_CONTACTS(state, contacts) {
    state.contacts = contacts;
    state.contactsFetched = true;
  },
  ADD_CONTACT(state, contact) {
    state.contacts.push(contact);
  },
  UPDATE_CONTACT(state, contact) {
    var idx = state.contacts.findIndex((x) => x.id == contact.id);
    Vue.set(state.contacts, idx, contact);
  },
  DELETE_CONTACT(state, contact) {
    var idx = state.contacts.findIndex((x) => x.id == contact.id);
    state.contacts.splice(idx, 1);
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
