import Vue from "vue";

const state = {
  applicationsFetched: false,
  lastFetch: 0,
  newApplication: {
    applicationid: "",
    name: "",
    description: "",
  },
  applications: [
    // {
    //   applicationid: "cwms",
    //   name: "COBI.wms",
    //   description: "COBI Warehouse Management System",
    // },
    // {
    //   applicationid: "ctime",
    //   name: "COBI.time",
    //   description: "COBI time",
    // },
    // {
    //   applicationid: "cppc",
    //   name: "COBI.ppc",
    //   description: "COBI Production Planning and Controlling",
    // },
    // {
    //   applicationid: "cship",
    //   name: "COBI.shipping",
    //   description: "COBI shipping",
    // },
  ],
};

const getters = {
  allApplications: (state) => state.applications,
  allApplicationsForFiltering: (state) => {
    let tmp = []
    tmp.push(...state.applications)
    tmp.push({
      applicationid: null,
      name: "All",
      description: "",
    });
    return tmp;
  },
  newApplication: (state) => state.newApplication,
  applicationById: (state) => (applicationid) => {
    return state.applications.find((x) => x.applicationid == applicationid);
  },
};

const actions = {
  fetchApplications({ commit }) {
    Vue.prototype.$axios.get("/applications").then((response) => {
      commit("SET_APPLICATIONS", response.data);
    });
  },
  fetchApplicationsAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/applications")
        .then((response) => {
          commit("SET_APPLICATIONS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async postApplication({ commit }, application) {
    try {
      var response = await Vue.prototype.$axios.post(
        "/applications",
        application
      );
      application.applicationid = response.data.applicationid
      commit("ADD_APPLICATION", application);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
  async patchApplication({ commit }, application) {
    try {
      var response = await Vue.prototype.$axios.patch(
        "/applications",
        application
      );
      commit("UPDATE_APPLICATION", application);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
  async deleteApplication({ commit }, applicationid) {
    try {
      var response = await Vue.prototype.$axios.delete(
        "/applications",
        { data: { "id": applicationid } }
      );
      commit("DELETE_APPLICATION", applicationid);
    } catch (exception) {
      console.log(exception);
      return exception.response.data[0].message;
    }
  },
};

const mutations = {
  SET_APPLICATIONS(state, applications) {
    state.applications = applications;
    state.applicationsFetched = true;
  },
  ADD_APPLICATION(state, application) {
    state.applications.push(application);
  },
  UPDATE_APPLICATION(state, application) {
    var a = state.applications.find(
      (x) => x.applicationid == application.applicationid
    );
    Object.keys(application).forEach((key) => {
      a[key] = application[key];
    });
  },
  DELETE_APPLICATION(state, applicationid) {
    const index = state.applications.findIndex(x => x.applicationid == applicationid);
    state.applications.splice(index, 1)
  }
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
