import Vue from "vue";

const state = {
    newIssue: {
        id: "",
        subject: "",
        description: "",
        priority: { id: 2, name: "Normal" },
        status: { id: 10, name: "Backlog" },
        tracker: { id: 72, name: "IT" },
    },
    issues: [],
    statuses: [],
    priorities: [],
    trackers: [],
}

const getters = {
    getNewIssue: (state) => state.newIssue,
    getIssuesOfProject: (state) => state.issues,
    getIssuesOfProjectPreview: (state) => state.issues.slice(0, 5),

    getAllStatuses: (state) => state.statuses,
    getAllPriorities: (state) => state.priorities,
    getAllTrackers: (state) => state.trackers,
}

const actions = {
    //ADMIN
    fetchIssuesOfProject({ commit }, projectid) {
        Vue.prototype.$axios.get(`/issues?project_id=${projectid}`)
            .then((response) => {
                commit("SET_ISSUES_FOR_PROJECT", response.data);
            });
    },
    fetchIssueWithJournalsOfProject({ commit }, issueid) {
        return Vue.prototype.$axios.get(`/issues/${issueid}`)
            .then((response) => {
                commit("UPDATE_ISSUES_FOR_PROJECT", response.data);
                return response.data;
            });
    },
    updateIssue({ commit }, issue) {
        console.log(issue);
        return Vue.prototype.$axios.put(`/issues/${issue.id}`, { // TODO issue
            "project": issue.project,
            "subject": issue.subject,
            "priority": issue.priority,
            "status": issue.status,
            "tracker": issue.tracker,
            "description": issue.description,
            "notes": issue.notes
        }).then(response => {
            delete issue.notes;
            commit("UPDATE_ISSUES_FOR_PROJECT", issue);
        }).catch(e => "Could not update Issue");
    },
    postIssue({ commit }, issue) {
        delete issue.id;
        return Vue.prototype.$axios.post(`/issues`, issue).then((response) => {
            commit("ADD_ISSUES_FOR_PROJECT", response.data);
            return { issue: response.data };
        }).catch(e => { return { error: "Could not create Issue" } });
    },
    deleteIssue({ commit }, delIss) {
        return Vue.prototype.$axios.delete(`/issues/${delIss.issue_id}`, {
            data: {
                "project_id": delIss.project_id
            }
        }).then((response) => {
            commit("REMOVE_ISSUES_FOR_PROJECT", delIss.issue_id);
        }).catch(e => "Somthing went wrong");
    },

    fetchAllStatuses({ commit }) {
        return Vue.prototype.$axios.get(`/status`)
            .then((response) => {
                commit("SET_STATUS", response.data);
            }).catch(e => "Somthing went wrong");
    },
    fetchAllPriority({ commit }) {
        return Vue.prototype.$axios.get(`/priority`)
            .then((response) => {
                commit("SET_PRIORITY", response.data);
            }).catch(e => "Somthing went wrong");
    },
    fetchAllTracker({ commit }) {
        return Vue.prototype.$axios.get(`/trackers`).then((response) => {
            commit("SET_TRACKERS", response.data.success);
        }).catch(e => "Somthing went wrong");
    },
}

const mutations = {
    SET_ISSUES_FOR_PROJECT(state, issues) {
        state.issues = issues.issues;
    },
    UPDATE_ISSUES_FOR_PROJECT(state, issue) {
        const i = state.issues.findIndex((x) => x.id == issue.id);
        Vue.set(state.issues, i, issue);
    },
    ADD_ISSUES_FOR_PROJECT(state, issue) {
        state.issues.push(issue);
    },
    REMOVE_ISSUES_FOR_PROJECT(state, issueid) {
        const i = state.issues.findIndex((x) => x.id == issueid);
        state.issues.splice(i, 1);
    },
    SET_STATUS(state, statuses) {
        state.statuses = statuses;
    },
    SET_PRIORITY(state, priorities) {
        state.priorities = priorities;
    },
    SET_TRACKERS(state, trackers) {
        state.trackers = trackers;
    },
}

export default {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
