import Vue from "vue";

const state = {
    projects: [],
    selectedProject: null,
}

const getters = {
    getAllProjects: (state) => state.projects,
    getProjectById: (id) => (state) => state.projects.find(e => e.id == id),
    getSelectedProject: (state) => state.selectedProject,
}

const actions = {
    //ADMIN
    fetchAllProjects({ commit }, offset) {
        return Vue.prototype.$axios.get(`/all/${offset}`).then((response) => {
            console.log(response.data);
            if (offset == 0) {
                commit("SET_PROJECTS", response.data);
            } else {
                commit("ADD_TO_PROJECTS", response.data);
            }
            return response.data.length;
        });
    },
    fetchAllProjectsByName({ commit }, query, offset) {
        return Vue.prototype.$axios.get(`/find`, { params: { q: query, offset: offset } },
        ).then((response) => {
            console.log(response.data);
            if (offset == 0) {
                commit("SET_PROJECTS", response.data);
            } else {
                commit("ADD_TO_PROJECTS", response.data);
            }
            return response.data.length;
        });
    },
    fetchProjectById({ commit }, projectid) {
        Vue.prototype.$axios.get(`/project/${projectid}`).then((response) => {
            commit("SELECT_PROJECT", response.data);
        });
    },
    createProject({ commit }, customerNameAndId) {
        return Vue.prototype.$axios.post(`/project`, { "name": customerNameAndId.name, "customerid": customerNameAndId.customerid, "gpcode": customerNameAndId.gpcode }).then((response) => {
            commit("SELECT_PROJECT", response.data);
            console.log(response.data.project);
            return { "projectid": response.data.project.id, "projectid_string": response.data.project.identifier };
        });
    },
}

const mutations = {
    SET_PROJECTS(state, projects) {
        state.projects = projects;
    },
    ADD_TO_PROJECTS(state, projects) {
        state.projects.push(...projects);
    },
    SELECT_PROJECT(state, project) {
        state.selectedProject = project;
    },
}

export default {
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations,
};
