import axios from "axios";
import Vue from "vue";

const state = {
  termsofserviceagreementsFetched: false,
  lastFetch: 0,
  termsofserviceagreements: [],
  newTermsofserviceagreement: {
    userid: null,
    termsofserviceid: null,
    agreementtime: "",
  },
};

const getters = {
  allTermsofserviceagreements: (state) => state.termsofserviceagreements,
  newTermsofserviceagreement: (state) =>
    JSON.parse(JSON.stringify(state.newTermsofserviceagreement)),
};

const actions = {
  fetchTermsofserviceagreementsAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/termsofserviceagreements")
        .then((response) => {
          commit("SET_TERMSOFSERVICEAGREEMENTS", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  testTermsofserviceagreement({ commit }, termsofserviceagreement) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .post("/termsofserviceagreements", termsofserviceagreement)
        .then((response) => {
          console.log("response:");
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log("error:");
          console.log(error);
          reject(error);
        });
    });
  },
  postTermsofserviceagreement({ commit }, id) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .post("/termsofserviceagreements", { termsofserviceid: id })
        .then((response) => {
          commit("ADD_TERMSOFSERVICEAGREEMENT", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_TERMSOFSERVICEAGREEMENTS(state, termsofserviceagreements) {
    state.termsofserviceagreements = termsofserviceagreements;
    state.termsofserviceagreementsFetched = true;
  },
  ADD_TERMSOFSERVICEAGREEMENT(state, termsofserviceagreement) {
    state.termsofserviceagreements.push(termsofserviceagreement);
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
