import Vue from "vue";

const state = {
  profileFetched: false,
  profile: {
    city: "",
    contact: "",
    contactname: "",
    country: "",
    customerid: 0,
    email: "",
    hnumber: "",
    name: "",
    state: "",
    street: "",
    tel: "",
    userid: 0,
    username: "admin",
    zip: "",
  },
};

const getters = {
  profile: (state) => state.profile,
};

const actions = {
  fetchProfileAsync({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/profile")
        .then((response) => {
          commit("SET_PROFILE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async patchProfile({ commit }, profile) {
    try {
      var response = await Vue.prototype.$axios.patch("/profile", profile);
      commit("UPDATE_PROFILE", profile);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
};

const mutations = {
  SET_PROFILE(state, profile) {
    state.profile = profile;
    state.profileFetched = true;
  },
  UPDATE_PROFILE(state, profile) {
    state.profile = profile;
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
