import axios from "axios";
import Vue from "vue";

const state = {
  contentsFetched: false,
  newContent: {
    contentid: null,
    name: "",
    description: "",
    filename: "",
    type: "",
    public: "",
    file: "",
  },
  contents: [
    // {
    //   contentid: 18,
    //   name: "myfile",
    //   description: "myfile",
    //   filename: "myfile.jpg",
    //   type: "image/jpeg",
    //   public: true,
    // },
    // {
    //   contentid: 19,
    //   name: "ganz dünned eis.jpg",
    //   description: "ganz dünned eis.jpg",
    //   filename: "ganz dünned eis.jpg",
    //   type: "image/jpeg",
    //   public: true,
    // },
    // {
    //   contentid: 20,
    //   name: "testvid",
    //   description: "testvid",
    //   filename: "testvid.mp4",
    //   type: "image/jpeg",
    //   public: true,
    // },
    // {
    //   contentid: 22,
    //   name: "myfile",
    //   description: "myfile",
    //   filename: "myfile.jpg",
    //   type: "image/jpeg",
    //   public: true,
    // },
  ],
};

const getters = {
  allContents: (state) => state.contents,
};

const actions = {
  fetchContents({ commit }) {
    Vue.prototype.$axios.get("/contents").then((response) => {
      commit("SET_CONTENTS", response.data);
    });
  },
  fetchContentsAsync({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/contents")
        .then((response) => {
          commit("SET_CONTENTS", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_CONTENTS(state, contents) {
    state.contents = contents;
    state.contentsFetched = true;
  },
  ADD_CONTENT(state, content) {
    state.contents.push(content);
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
