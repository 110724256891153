import axios from "axios";
import Vue from "vue";

const state = {
  messaginglistsFetched: false,
  lastFetch: 0,
  messaginglists: [],
  newMessaginglist: {
    name: "",
    description: "",
  },
  /*   {
      name: "",
      userid: 1,
      newMessages:2
    } */
  groups: [],
};

const getters = {
  allMessaginglists: (state) => state.messaginglists,
  newMessaginglist: (state) => JSON.parse(JSON.stringify(state.newMessaginglist)),
  getAllGroupsOfUser: (state) => state.groups,
  messaginglistById: (state) => (id) => {
    return state.messaginglists.find((x) => x.id == id);
  },
};

const actions = {
  fetchGroupsOfUser({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/messaginglists/groups")
        .then((response) => {
          console.log(response.data);
          commit("SET_GROUPS", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchMessaginglistsAsync({ commit }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else return;
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/messaginglists")
        .then((response) => {
          commit("SET_MESSAGINGLISTS", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postMessaginglist({ commit }, messaginglist) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .post("/messaginglists", messaginglist)
        .then((response) => {
          commit("ADD_MESSAGINGLIST", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data[0].message);
        });
    });
  },
  patchMessaginglist({ commit }, messaginglist) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .patch("/messaginglists", messaginglist)
        .then((response) => {
          commit("UPDATE_MESSAGINGLIST", messaginglist);
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data[0].message);
        });
    });
  },
  deleteMessaginglist({ commit }, messaginglist) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .delete("/messaginglists", { data: messaginglist })
        .then((response) => {
          commit("DELETE_MESSAGINGLIST", messaginglist);
          resolve(messaginglist);
        })
        .catch((error) => {
          reject(error.response.data[0].message);
        });
    });
  },

};

const mutations = {
  SET_GROUPS(state, groups) {
    state.groups = groups;
  },

  //Messaginglists
  SET_MESSAGINGLISTS(state, messaginglists) {
    state.messaginglists = messaginglists;
  },
  UPDATE_MESSAGINGLIST(state, messaginglist) {
    var idx = state.messaginglists.findIndex((x) => x.id == messaginglist.id);
    Vue.set(state.messaginglists, idx, messaginglist);
  },
  ADD_MESSAGINGLIST(state, messaginglist) {
    state.messaginglists.push(messaginglist);
  },
  DELETE_MESSAGINGLIST(state, messaginglist) {
    var idx = state.messaginglists.findIndex((x) => x.id == messaginglist.id);
    state.messaginglists.splice(idx, 1);
  },

};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
