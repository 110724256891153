import axios from "axios";
import Vue from "vue";

const state = {
  announcementFetched: false,
  lastFetch: 0,
  newAnnouncement: {
    announcementid: null,
    announcementtitle: "",
    announcementtext: "",
    expirationdate: null,
    topictypes: null
  },
  announcement: {
    announcementid: 0,
    announcementtitle: "Keine Aktuellen Announcements",
    announcementtext: "",
    expirationdate: '2023-04-19T00:00:00.000Z',
    topictypes: []
  },
  allAnnouncements: [],
};

const getters = {
  getAnnouncement: (state) => state.announcement,
  getAllAnnouncements: (state) => state.allAnnouncements.sort((a, b) => new Date(a.expirationdate).getTime() - new Date(b.expirationdate).getTime()),
  getNotExpiredAnnouncements: (state) => state.allAnnouncements.filter(a => (new Date(a.expirationdate).getTime() - Date.now()) > 0),
  getExpiredAnnouncements: (state) => state.allAnnouncements.filter(a => (new Date(a.expirationdate).getTime() - Date.now()) <= 0),
  newAnnouncement: (state) => JSON.parse(JSON.stringify(state.newAnnouncement)),
};

const actions = {
  fetchAnnouncementAsync({ commit, state }) {
    if (state.lastFetch + 2000 < Date.now()) {
      state.lastFetch = Date.now();
    } else {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .get("/announcements")
        .then((response) => {
          commit("SET_ANNOUNCEMENT", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllAnnouncements({ commit }) {
    return Vue.prototype.$axios
      .get("/announcements/all")
      .then((response) => {
        commit("SET_ALL_ANNOUNCEMENTS", response.data);
      })
      .catch((error) => error.response.data[0].message);
  },
  async postAnnouncement({ commit }, announcement) {
    try {
      let formData = new FormData();
      console.log(announcement);

      formData.append("file", announcement.file);
      formData.append("fileData", announcement.fileData);
      formData.append("announcementid", announcement.announcementid);
      formData.append("announcementtext", announcement.announcementtext);
      formData.append("announcementtitle", announcement.announcementtitle);
      formData.append("expirationdate", announcement.expirationdate);
      formData.append("topictype", announcement.topictype);

      var response = await Vue.prototype.$axios.post(
        "/announcements",
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        },
      );
      commit("SET_ANNOUNCEMENT", response.data);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
  async patchAnnouncement({ commit }, announcement) {
    try {
      var response = await Vue.prototype.$axios.patch(
        "/announcements",
        announcement
      );
      commit("UPDATE_ANNOUNCEMENT", announcement);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
  async deleteAnnouncement({ commit }, announcementid) {
    try {
      var response = await Vue.prototype.$axios.delete(
        "/announcements",
        { data: { 'id': announcementid } }
      );
      commit("REMOVE_ANNOUNCEMENT", announcementid);
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
};

const mutations = {
  SET_ANNOUNCEMENT(state, announcement) {
    state.announcement = announcement;
    state.announcementFetched = true;
  },
  SET_ALL_ANNOUNCEMENTS(state, allAnnouncements) {
    state.allAnnouncements = allAnnouncements;
  },
  UPDATE_ANNOUNCEMENT(state, announcement) {
    const index = state.allAnnouncements.findIndex(x => x.id == announcement.id)
    state.allAnnouncements[index] = announcement
  },
  REMOVE_ANNOUNCEMENT(state, announcementid) {
    const index = state.allAnnouncements.findIndex(x => x.id == announcementid);
    state.allAnnouncements.splice(index, 1)
  },
};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
