import axios from "axios";
import Vue from "vue";

const state = {
  newPasswordReset: {
    password: "",
    newPassword: "",
  },
  newPasswordResetWithUsername: {
    email: "",
    password: "",
    newPassword: "",
  },
};

const getters = {
  newPasswordReset: (state) => state.newPasswordReset,
  newPasswordResetWithUsername: (state) => state.newPasswordResetWithUsername,
};

const actions = {
  patchPasswordWithUsername({ commit }, newPasswordResetWithUsername) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$axios
        .patch("/passwordWithUsername", newPasswordResetWithUsername)
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async patchPassword({ commit }, newPasswordReset) {
    try {
      var response = await Vue.prototype.$axios.patch(
        "/password",
        newPasswordReset
      );
    } catch (exception) {
      return exception.response.data[0].message;
    }
  },
};

const mutations = {};

export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
